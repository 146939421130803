import React from "react";
import {
	Box,
	Text,
	Flex,
	Heading,
	Icon,
	SimpleGrid,
	Card,
	CardHeader,
	Image,
	Container,
} from "@chakra-ui/react";
import {
	FcAssistant,
	FcCollaboration,
	FcDonate,
	FcPositiveDynamic,
	FcMoneyTransfer,
} from "react-icons/fc";

// Example usage with posts data
const Revenue = () => {
	return (
		<>
			<Container
				maxW="container.sm"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				minH={"calc(100vh - 160px)"}
			>
				<SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>
					<Card>
						<CardHeader>
							<Flex spacing="4">
								<Flex flex="1" gap="4" alignItems="center">
									<Icon as={FcAssistant} w={10} h={10} />

									<Box>
										<Heading size="sm">1. แชร์ลิ้งค์แนะนำ</Heading>
										<Text>แนะนำบริการตามช่องทางต่างๆ พร้อมแนบลิ้งค์</Text>
									</Box>
								</Flex>
							</Flex>
						</CardHeader>
						<SimpleGrid
							p={5}
							pt={0}
							columns={{ base: 2, md: 3 }}
							spacing={5}
							display={"none"}
						>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<CardHeader>
							<Flex spacing="4">
								<Flex flex="1" gap="4" alignItems="center">
									<Icon as={FcCollaboration} w={10} h={10} />

									<Box>
										<Heading size="sm">2. มีผู้มาสมัคร</Heading>
										<Text>ทดลองใช้งานระบบฟรี 30 วัน</Text>
									</Box>
								</Flex>
							</Flex>
						</CardHeader>
						<SimpleGrid
							p={5}
							pt={0}
							columns={{ base: 2, md: 3 }}
							spacing={5}
							display={"none"}
						>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<CardHeader>
							<Flex spacing="4">
								<Flex flex="1" gap="4" alignItems="center">
									<Icon as={FcDonate} w={10} h={10} />

									<Box>
										<Heading size="sm">3. เมื่อต่ออายุ</Heading>
										<Text>
											ผู้แนะนำรับ{" "}
											<Text
												as="span"
												fontSize={"lg"}
												fontWeight="bold"
												color={"cyan.600"}
											>
												20%
											</Text>{" "}
											ของทุกการต่ออายุ (ตลอดไป)
										</Text>
									</Box>
								</Flex>
							</Flex>
						</CardHeader>
						<SimpleGrid
							p={5}
							pt={0}
							columns={{ base: 2, md: 3 }}
							spacing={5}
							display={"none"}
						>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<CardHeader>
							<Flex spacing="4">
								<Flex flex="1" gap="4" alignItems="center">
									<Icon as={FcPositiveDynamic} w={10} h={10} />

									<Box>
										<Heading size="sm">4. เพิ่มข้อมูลธนาคาร</Heading>
										<Text>เพื่อใช้สำหรับรับเงินจากระบบ</Text>
									</Box>
								</Flex>
							</Flex>
						</CardHeader>
						<SimpleGrid
							p={5}
							pt={0}
							columns={{ base: 2, md: 3 }}
							spacing={5}
							display={"none"}
						>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<CardHeader>
							<Flex spacing="4">
								<Flex flex="1" gap="4" alignItems="center">
									<Icon as={FcMoneyTransfer} w={10} h={10} />

									<Box>
										<Heading size="sm">5. โอนเงิน</Heading>
										<Text>
											หากมียอดรอถอนระบบจะทำการโอนเงินตามบัญชีธนาคารที่ระบุไว้ทุกวันที่
											5
										</Text>
									</Box>
								</Flex>
							</Flex>
						</CardHeader>
						<SimpleGrid
							p={5}
							pt={0}
							columns={{ base: 2, md: 3 }}
							spacing={5}
							display={"none"}
						>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
							<Image
								objectFit="cover"
								src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
								alt="Chakra UI"
								w={"full"}
								borderRadius={"lg"}
							/>
						</SimpleGrid>
					</Card>
					<Box
						textAlign="center"
						// minHeight="100vh"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Text pt={4}>
							ขอบคุณสำหรับความสนใจ
							และหวังว่าเราจะเป็นอีกหนึ่งช่องทางในการสร้างรายได้ประจำให้แก่ท่าน
						</Text>
						<Box>
							<Text ml={2} pt={0.5}>
								Thank You
							</Text>
						</Box>
					</Box>
				</SimpleGrid>
			</Container>
		</>
	);
};

export default Revenue;
