import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import { useTranslation } from "react-i18next";
import {
	Flex,
	Container,
	Box,
	Text,
	useColorModeValue,
	VStack,
	List,
	ListItem,
	ListIcon,
	HStack,
	Heading,
	Button,
	Stack,
	SimpleGrid,
	useToast,
	ScaleFade,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";
import { MdHotel } from "react-icons/md";

function CardPrice({ businessType, priceCount, showFull }) {
	const { t } = useTranslation();
	const bgColor = useColorModeValue("gray.50", "gray.600");
	const [activeButton, setActiveButton] = useState(
		businessType === "restaurant" ? 0 : 1
	);
	const handleRestaurantClick = () => {
		setActiveButton(0);
	};
	const handleAccommodationClick = () => {
		setActiveButton(1);
	};

	const choice = [
		{
			price: [90, 150, 210],
			unit_name: "โต๊ะ",
		},
		{
			price: [60, 90, 120],
			unit_name: "ห้อง",
		},
	];

	const toast = useToast();
	const [price, setPrice] = useState("");
	useEffect(() => {
		const getPricePromotion = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/api/marketing/price_promotion`
				);
				setPrice(response.data.results[0].price);
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					description: `กรุณาติดต่อพนักงาน`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		};
		getPricePromotion();
	}, [toast]);

	return (
		<>
			{showFull ? (
				<>
					<Flex
						justifyContent="center"
						alignItems="center"
						minH="calc(100vh - 200px)"
					>
						<Container maxW="container.lg" py={5}>
							<Stack spacing={0} textAlign={"center"}>
								<Heading>{t("page_pricing_header")}</Heading>
								<Text color={"gray.500"}>
									เพิ่มยอดขายและความสะดวกสบายให้ธุรกิจของคุณ
								</Text>
							</Stack>
							<ScaleFade initialScale={0.5} in={true}>
								<Container
									maxW="container.xl"
									textAlign="center"
									justify="center"
									pb={5}
									pt={{ base: 3, md: 5 }}
								>
									<Box>
										<SimpleGrid
											columns={{ base: 1, sm: 3 }}
											spacing={{ base: 2, md: 6, lg: 8 }}
										>
											<Box
												borderRadius={"2xl"}
												border={"1px solid #e7e7e7"}
												py={5}
											>
												<Text
													fontWeight="600"
													fontSize={{ base: "xl", md: "2xl" }}
													mb={{ base: 0, md: 2 }}
													color={"cyan.700"}
												>
													ต่ออายุ 1 ปี
												</Text>
												<HStack justifyContent="center">
													<Text
														fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
														fontWeight="600"
													>
														฿
													</Text>
													<Text
														fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
														fontWeight="900"
													>
														{price === "" ? (
															<BeatLoader size={8} color="gray" />
														) : (
															(price * 1).toFixed(0)
														)}
													</Text>
												</HStack>
												<Text>เฉลี่ยวันละ {(price / 365).toFixed(2)} บาท</Text>
											</Box>
											<Box
												borderRadius={"2xl"}
												border={"1px solid #e7e7e7"}
												py={5}
											>
												<Text
													fontWeight="600"
													fontSize={{ base: "xl", md: "2xl" }}
													mb={{ base: 0, md: 2 }}
													color={"cyan.700"}
												>
													ต่ออายุ 2 ปี
												</Text>
												<HStack justifyContent="center">
													<Text
														fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
														fontWeight="600"
													>
														฿
													</Text>
													<Text
														fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
														fontWeight="900"
													>
														{price === "" ? (
															<BeatLoader size={8} color="gray" />
														) : (
															(price * 2 * 0.9).toFixed(0)
														)}
													</Text>
												</HStack>
												<Text>
													เฉลี่ยวันละ{" "}
													{((price * 2 * 0.9) / (365 * 2)).toFixed(2)} บาท
												</Text>
											</Box>
											<Box
												borderRadius={"2xl"}
												border={"1px solid #e7e7e7"}
												py={5}
											>
												<Text
													fontWeight="600"
													fontSize={{ base: "xl", md: "2xl" }}
													mb={{ base: 0, md: 2 }}
													color={"cyan.700"}
												>
													ต่ออายุ 3 ปี
												</Text>
												<HStack justifyContent="center">
													<Text
														fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
														fontWeight="600"
													>
														฿
													</Text>
													<Text
														fontSize={{ base: "2xl", md: "3xl", xl: "4xl" }}
														fontWeight="900"
													>
														{price === "" ? (
															<BeatLoader size={8} color="gray" />
														) : (
															(price * 3 * 0.8).toFixed(0)
														)}
													</Text>
												</HStack>
												<Text>
													เฉลี่ยวันละ{" "}
													{((price * 3 * 0.8) / (365 * 3)).toFixed(2)} บาท
												</Text>
											</Box>
										</SimpleGrid>
									</Box>
									<Box
										display="none"
										alignItems="center"
										justifyContent="center"
										my={2}
									>
										<Stack direction="row" spacing={4}>
											<Button
												onClick={handleRestaurantClick}
												color={activeButton ? "cyan.700" : "gray.100"}
												bgColor={!activeButton ? "cyan.700" : "gray.100"}
												_hover={"none"}
												leftIcon={<IoRestaurant />}
												w={36}
											>
												ร้านอาหาร
											</Button>
											<Button
												onClick={handleAccommodationClick}
												color={!activeButton ? "cyan.700" : "gray.100"}
												bgColor={activeButton ? "cyan.700" : "gray.100"}
												_hover={"none"}
												leftIcon={<MdHotel />}
												w={36}
											>
												ที่พัก
											</Button>
										</Stack>
									</Box>
									<Flex
										display={"none"}
										overflowX="auto"
										justify={{ base: "auto", md: "auto", lg: "center" }}
										gridGap={3}
										py={3}
										px={{ base: 0, lg: 3 }}
										pb={4}
									>
										<Flex gap={{ base: 8, md: 10, xl: 12 }} px={5}>
											<Box
												pt={3}
												borderRadius={"2xl"}
												border={"1px solid #e7e7e7"}
												w={{ base: "15em", md: "18em" }}
											>
												<Box py={{ base: 2, md: 4 }} px={{ base: 3, md: 5 }}>
													<Text
														fontWeight="600"
														fontSize="2xl"
														mb={{ base: 0, md: 2 }}
														color={"cyan.700"}
													>
														ขนาดเริ่มต้น
													</Text>
													<HStack justifyContent="center">
														<Text fontSize="3xl" fontWeight="600">
															฿
														</Text>
														<Text
															fontSize={{ base: "4xl", md: "5xl" }}
															fontWeight="900"
														>
															{choice[activeButton].price[0]}
														</Text>
														<Text fontSize="xl" color="gray.500">
															/30 วัน
														</Text>
													</HStack>
													<Text size={"xs"}>
														เฉลี่ยวันละ {choice[activeButton].price[0] / 30} บาท
													</Text>
												</Box>
												<VStack
													bg={bgColor}
													py={{ base: 5, md: 7 }}
													borderBottomRadius={"2xl"}
												>
													<List
														spacing={{ base: 1, md: 3 }}
														textAlign="start"
														px={2}
													>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															จำนวน 1 - 15 {choice[activeButton].unit_name}
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															แอดมินจัดการร้าน
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															ใช้งาน QR Menu
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															แจ้งรายการผ่าน LINE
														</ListItem>
														<ListItem>
															{!activeButton ? (
																<ListIcon
																	as={FaCheckCircle}
																	color="green.500"
																	fontSize={"1.4em"}
																/>
															) : (
																<ListIcon
																	as={FaTimesCircle}
																	color="red.500"
																	fontSize={"1.4em"}
																/>
															)}
															จดออเดอร์บนมือถือ
														</ListItem>
													</List>
												</VStack>
											</Box>
											<Box
												pt={3}
												borderRadius={"2xl"}
												border={"1px solid #e7e7e7"}
												w={{ base: "15em", md: "18em" }}
											>
												<Box py={{ base: 2, md: 4 }} px={{ base: 3, md: 5 }}>
													<Text
														fontWeight="600"
														fontSize="2xl"
														mb={{ base: 0, md: 2 }}
														color={"cyan.700"}
													>
														ขนาดกลาง
													</Text>
													<HStack justifyContent="center">
														<Text fontSize="3xl" fontWeight="600">
															฿
														</Text>
														<Text
															fontSize={{ base: "4xl", md: "5xl" }}
															fontWeight="900"
														>
															{choice[activeButton].price[1]}
														</Text>
														<Text fontSize="xl" color="gray.500">
															/30 วัน
														</Text>
													</HStack>
													<Text size={"xs"}>
														เฉลี่ยวันละ {choice[activeButton].price[1] / 30} บาท
													</Text>
												</Box>
												<VStack
													bg={bgColor}
													py={{ base: 5, md: 7 }}
													borderBottomRadius={"2xl"}
												>
													<List
														spacing={{ base: 1, md: 3 }}
														textAlign="start"
														px={2}
													>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															จำนวน 16 - 30 {choice[activeButton].unit_name}
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															แอดมินจัดการร้าน
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															ใช้งาน QR Menu
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															แจ้งรายการผ่าน LINE
														</ListItem>
														<ListItem>
															{!activeButton ? (
																<ListIcon
																	as={FaCheckCircle}
																	color="green.500"
																	fontSize={"1.4em"}
																/>
															) : (
																<ListIcon
																	as={FaTimesCircle}
																	color="red.500"
																	fontSize={"1.4em"}
																/>
															)}
															จดออเดอร์บนมือถือ
														</ListItem>
													</List>
												</VStack>
											</Box>
											<Box
												pt={3}
												borderRadius={"2xl"}
												border={"1px solid #e7e7e7"}
												w={{ base: "15em", md: "18em" }}
											>
												<Box py={{ base: 2, md: 4 }} px={{ base: 3, md: 5 }}>
													<Text
														fontWeight="600"
														fontSize="2xl"
														mb={{ base: 0, md: 2 }}
														color={"cyan.700"}
													>
														รองรับการเติบโต
													</Text>
													<HStack justifyContent="center">
														<Text fontSize="3xl" fontWeight="600">
															฿
														</Text>
														<Text
															fontSize={{ base: "4xl", md: "5xl" }}
															fontWeight="900"
														>
															{choice[activeButton].price[2]}
														</Text>
														<Text fontSize="xl" color="gray.500">
															/30 วัน
														</Text>
													</HStack>
													<Text size={"xs"}>
														เฉลี่ยวันละ {choice[activeButton].price[2] / 30} บาท
													</Text>
												</Box>
												<VStack
													bg={bgColor}
													py={{ base: 5, md: 7 }}
													borderBottomRadius={"2xl"}
												>
													<List
														spacing={{ base: 1, md: 3 }}
														textAlign="start"
														px={2}
													>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															จำนวน 31 - 50 {choice[activeButton].unit_name}
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															แอดมินจัดการร้าน
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															ใช้งาน QR Menu
														</ListItem>
														<ListItem>
															<ListIcon
																as={FaCheckCircle}
																color="green.500"
																fontSize={"1.4em"}
															/>
															แจ้งรายการผ่าน LINE
														</ListItem>
														<ListItem>
															{!activeButton ? (
																<ListIcon
																	as={FaCheckCircle}
																	color="green.500"
																	fontSize={"1.4em"}
																/>
															) : (
																<ListIcon
																	as={FaTimesCircle}
																	color="red.500"
																	fontSize={"1.4em"}
																/>
															)}
															จดออเดอร์บนมือถือ
														</ListItem>
													</List>
												</VStack>
											</Box>
										</Flex>
									</Flex>
								</Container>
							</ScaleFade>
						</Container>
					</Flex>
				</>
			) : (
				<Flex justifyContent="center" alignItems="center">
					<Box
						bgColor={"cyan.700"}
						w={{ base: "3xl", md: "4xl" }}
						borderTopRadius={"xl"}
					>
						<HStack justifyContent="center">
							<Flex pt={3} gap={2} bgClip="text" color={"cyan.100"}>
								<Text fontSize={{ base: "md", md: "xl" }} fontWeight="400">
									ต้นทุนระบบเฉลี่ยเพียงวันละ
								</Text>
								<Text
									fontSize={{ base: "2xl", md: "3xl" }}
									fontWeight="800"
									mt={{ base: "-8px", md: "-10px" }}
								>
									{price && (price / 365).toFixed(2)}
								</Text>
								<Text fontSize={{ base: "md", md: "xl" }} fontWeight="400">
									บาทเท่านั้น
								</Text>
							</Flex>
						</HStack>
						<Text
							size={"xs"}
							fontWeight={"300"}
							textAlign={"center"}
							color={"gray.500"}
							display={"none"}
						>
							จำกัด {priceCount} ร้านสุดท้าย
						</Text>
					</Box>
				</Flex>
			)}
		</>
	);
}

export default CardPrice;
