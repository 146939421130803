import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
	Flex,
	Spacer,
	Text,
	Select,
	Box,
	Icon,
	Button,
	Container,
	Card,
	Divider,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { WarningIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/AuthProvider";
import { API_BASE_URL } from "../../config/apiConfig";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router

const stripePromise = loadStripe(
	"pk_live_51PCwhF2LZUy9EH8lQ8kyvSs1nn5DtqN3FWbpCQyDso1Y7jr0IZJjB2IyU8U5foWpN3zXn2goVj0fT7QijD2sHLDX00MKjFI0O3"
);

export const CheckoutForm = () => {
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);
	const [planNumberDate, setPlanNumberDate] = useState(null); // Set initial state to null
	const [isLoading, setIsloading] = useState(0);
	const [clientSecret, setClientSecret] = useState(null);
	const [key, setKey] = useState(0); // This will help force remounting

	// Fetch clientSecret only when button is clicked
	const fetchClientSecret = useCallback(() => {
		setIsloading(1);
		if (planNumberDate !== null) {
			axios
				.post(
					`${API_BASE_URL}/stripe/checkout`,
					{ plan: planNumberDate },
					{ headers }
				)
				.then((response) => {
					setIsloading(0);
					setClientSecret(response.data.clientSecret);
					setKey((prevKey) => prevKey + 1); // Increment key to remount the provider
				})
				.catch((error) => {
					console.error("Error fetching clientSecret:", error);
				});
		}
	}, [planNumberDate, headers]);

	return (
		<div id="checkout">
			<Box
				px={{ base: 6, md: 10 }}
				pt={{ base: 5, md: 8 }}
				pb={{ base: 5, md: 4 }}
			>
				<Flex direction={{ base: "column", md: "row" }} mb={{ base: 5, md: 6 }}>
					<Text pt={2} color={"cyan.700"} fontWeight={"bold"}>
						ต่ออายุจำนวน
					</Text>
					<Spacer />
					<Flex>
						<Select
							w={{ base: "full", md: "250px" }}
							_hover={"none"}
							borderRightRadius={"none"}
							borderColor={"gray.100"}
							boxShadow={"sm"}
							bgColor="gray.100"
							cursor={"pointer"}
							focusBorderColor="transparent"
							value={planNumberDate ?? ""} // Ensure empty string for Select
							onChange={(e) =>
								setPlanNumberDate(
									e.target.value ? Number(e.target.value) : null
								)
							}
							textAlign={"center"}
						>
							<option value="">-เลือก-</option> {/* New option added */}
							<option value={1}>+1 ปี 730 บาท</option>
							<option value={2}>+2 ปี 1,314 บาท -10%</option>
							<option value={3}>+3 ปี 1,752 บาท -20%</option>
						</Select>

						<Button
							borderLeftRadius={"none"}
							border={"none"}
							w={"80px"}
							px={0}
							_hover={"none"}
							color={"white"}
							bgColor={"blue.500"}
							fontWeight={"400"}
							cursor={"pointer"}
							boxShadow={"base"}
							onClick={fetchClientSecret} // Fetch clientSecret when button clicked
							isDisabled={!planNumberDate} // Disable if no plan is selected
						>
							{isLoading ? <BeatLoader size={8} color="white" /> : "ยืนยัน"}
						</Button>
					</Flex>
				</Flex>

				{clientSecret && (
					<>
						<Divider
							mx="auto"
							color="gray.600"
							_dark={{
								color: "#F9FAFB",
							}}
							h="3.5px"
							mb={{ base: 2, md: 5, xl: 7 }}
						/>
						<EmbeddedCheckoutProvider
							key={key}
							stripe={stripePromise}
							options={{ clientSecret }}
						>
							<EmbeddedCheckout />
						</EmbeddedCheckoutProvider>
					</>
				)}
			</Box>
		</div>
	);
};

export const Return = () => {
	const navigate = useNavigate();
	const [status, setStatus] = useState(null);
	const [customerEmail, setCustomerEmail] = useState("");

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionId = urlParams.get("session_id");

		axios
			.get(`${API_BASE_URL}/stripe/session_status`, {
				params: { session_id: sessionId },
			})
			.then((response) => {
				// console.log("check_session_status", response.data);
				setStatus(response.data.status);
				setCustomerEmail(response.data.customer_email);
			})
			.catch((error) => {
				console.error("Error fetching session status:", error);
			});
	}, []);

	if (status === "expired" || status === "open") {
		return (
			<Flex
				w="auto"
				justifyContent="center"
				alignItems="center"
				minH="calc(100vh - 150px)"
			>
				<Container maxW={"3xl"} px={"none"}>
					<Card boxShadow={"none"} borderRadius={"1em"}>
						<Box
							textAlign="center"
							py={10}
							px={6}
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<Icon as={WarningIcon} boxSize={"50px"} color={"red.500"} />
							<Text fontSize="2xl" mt={3} mb={6}>
								การชำระเงินล้มเหลว
							</Text>
							<Text mb={6}>
								มีปัญหาในการประมวลผลการชำระเงินของคุณ
								โปรดลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน
							</Text>
							<Button
								mt={6}
								colorScheme="red"
								onClick={() => navigate("/checkout")}
							>
								ลองอีกครั้ง
							</Button>
						</Box>
					</Card>
				</Container>
			</Flex>
		);
	}

	if (status === "complete") {
		return (
			<section id="success">
				<Flex
					w="auto"
					justifyContent="center"
					alignItems="center"
					minH="calc(100vh - 150px)"
				>
					<Container maxW={"3xl"} px={"none"}>
						<Card boxShadow={"none"} borderRadius={"1em"}>
							<Box
								textAlign="center"
								py={10}
								px={6}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<Icon
									as={CheckCircleIcon}
									boxSize={"50px"}
									color={"green.500"}
								/>
								<Text fontSize="2xl" mt={3} mb={6}>
									ชำระเงินสำเร็จ!
								</Text>
								<Text>
									อีเมลยืนยันได้ถูกส่งไปยัง <b>{customerEmail}</b> แล้ว <br />
									ขอบคุณสำหรับความไว้วางใจของคุณ!
								</Text>
							</Box>
						</Card>
					</Container>
				</Flex>
			</section>
		);
	}

	return null;
};
