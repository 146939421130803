import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
	Box,
	Text,
	useToast,
	Switch,
	FormControl,
	FormLabel,
	Flex,
	Input,
	Button,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../../config/apiConfig";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

function Detail() {
	const toast = useToast();

	return (
		<>
			<Tabs>
				<TabList>
					<Tab>ทีมขาย</Tab>
					<Tab>สมาชิก</Tab>
					<Tab>มอนิเตอร์</Tab>
					<Tab>ราคา</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<Box px={{ base: 1, md: 10 }} py={{ base: 1, md: 5 }}>
							<TeamSaleWaitingWithdraw toast={toast} />
						</Box>
					</TabPanel>
					<TabPanel>
						<Box px={{ base: 1, md: 10 }} py={{ base: 1, md: 5 }}>
							<UserRegister toast={toast} />
						</Box>
					</TabPanel>
					<TabPanel>
						<Flex
							w="auto"
							justifyContent="center"
							alignItems="center"
							minH="calc(100vh - 150px)"
						>
							<ConnectionStatus />
						</Flex>
					</TabPanel>
					<TabPanel>
						<Flex
							w="auto"
							justifyContent="center"
							alignItems="center"
							minH="calc(100vh - 150px)"
						>
							<PriceUpdater toast={toast} />
						</Flex>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
}

const PriceUpdater = ({ toast }) => {
	const [nowPriceData, setNowPriceData] = useState([]);
	useEffect(() => {
		const handleGetPriceData = async () => {
			try {
				const response = await fetch(`${API_BASE_URL}/monitor/getPriceData`);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				// console.log("data", data[0]);
				setNowPriceData(data[0]);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast({
					title: "Error Fetching Data",
					description: error.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		};
		handleGetPriceData();
	}, [toast]);

	const updatePriceData = async () => {
		try {
			const response = await fetch(`${API_BASE_URL}/monitor/updatePriceData`, {
				method: "POST", // Change to POST method
				headers: {
					"Content-Type": "application/json", // Set content type to JSON
				},
				body: JSON.stringify({ nowPriceData }), // Add body with JSON payload
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.message === "success") {
				toast({
					title: "Update success",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			toast({
				title: "Error Fetching Data",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<Box
			p={5}
			w={{ base: "full", md: "50%", xl: "50%" }}
			bgColor={"gray.50"}
			borderRadius={"1em"}
		>
			<Text>PRICE</Text>
			<Input
				type="number"
				min={1}
				border={"none"}
				bgColor={"white"}
				value={nowPriceData?.price || ""}
				onChange={(e) =>
					setNowPriceData({ ...nowPriceData, price: e.target.value })
				}
			/>
			<Text>promotion_number</Text>
			<Input
				border={"none"}
				bgColor={"white"}
				value={nowPriceData?.promotion_number || ""}
				onChange={(e) =>
					setNowPriceData({
						...nowPriceData,
						promotion_number: e.target.value,
					})
				}
			/>
			<Text>promotion_percent</Text>
			<Input
				border={"none"}
				bgColor={"white"}
				value={nowPriceData?.promotion_percent || ""}
				onChange={(e) =>
					setNowPriceData({
						...nowPriceData,
						promotion_percent: e.target.value,
					})
				}
			/>
			<Text>count</Text>
			<Input
				border={"none"}
				bgColor={"white"}
				value={nowPriceData?.count || ""}
				onChange={(e) =>
					setNowPriceData({
						...nowPriceData,
						count: e.target.value,
					})
				}
			/>
			<Flex justifyContent="flex-end" mt={4}>
				<Button onClick={updatePriceData}>UPDATE</Button>
			</Flex>
		</Box>
	);
};

const ConnectionStatus = () => {
	const [chartOptions, setChartOptions] = useState({
		scales: {
			y: {
				beginAtZero: true,
				min: 0,
			},
		},
	});
	const [maxActive, setMaxActive] = useState(null); // State to track max active

	const intervalRef = useRef(null);
	const [responseData, setResponseData] = useState({
		max: null,
		active: null,
		free: null,
	});
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: "Active Connections",
				data: [],
				borderColor: "teal",
				backgroundColor: "rgba(0, 128, 128, 0.2)",
				tension: 0.4, // Smooth line
			},
		],
	});
	const toast = useToast();
	const [isChecking, setIsChecking] = useState(false);
	const updateChartData = (active) => {
		setChartData((prevData) => {
			const labels = [...prevData.labels, new Date().toLocaleTimeString()];
			const data = [...prevData.datasets[0].data, active];

			// Keep only the last 20 points
			if (labels.length > 20) {
				labels.shift();
				data.shift();
			}

			// Update max active value
			setMaxActive((prevMax) => {
				return prevMax !== null ? Math.max(prevMax, active) : active;
			});

			// Calculate dynamic y-axis min and max
			const min = Math.min(...data) - 5;
			const max = Math.max(...data) + 5;

			setChartOptions({
				scales: {
					y: {
						beginAtZero: true,
						min: min,
						max: max,
					},
				},
			});

			return {
				labels,
				datasets: [
					{
						...prevData.datasets[0],
						data,
					},
				],
			};
		});
	};

	useEffect(() => {
		const handleGetRequest = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/monitor/checkconnection`
				);

				const data = response.data;
				setResponseData({
					max: data.max,
					active: data.active,
					free: data.free,
				});
				updateChartData(data.active);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast({
					title: "Error Fetching Data",
					description: error.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		};

		if (isChecking) {
			intervalRef.current = setInterval(handleGetRequest, 2000); // Adjust interval time as needed
		} else {
			clearInterval(intervalRef.current);
		}

		return () => clearInterval(intervalRef.current);
	}, [isChecking, toast]);

	return (
		<Box p={5} w={{ base: "full", md: "50%", xl: "50%" }}>
			<FormControl display="flex" alignItems="center" mb={3}>
				<FormLabel htmlFor="check-connection" mb="0">
					Check Connection
				</FormLabel>
				<Switch
					id="check-connection"
					isChecked={isChecking}
					onChange={() => setIsChecking(!isChecking)}
				/>
			</FormControl>
			{responseData && (
				<>
					<Box>
						<Text fontWeight="bold">Response Data:</Text>
						<Text>{JSON.stringify(responseData, null, 2)}</Text>
						<Text fontWeight="bold" mt={4}>
							Max Active Connections All Time:{" "}
							{maxActive !== null ? maxActive : "N/A"}
						</Text>
						<Box mt={4} p={3} borderWidth={1} borderRadius="md">
							<Bar
								data={chartData}
								options={chartOptions}
								height={400}
								width={800}
							/>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};

const TeamSaleWaitingWithdraw = ({ toast }) => {
	const [teamSaleData, setTeamSaleData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [totalWaitingWithdraw, setTotalWaitingWithdraw] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: "ascending",
	});

	useEffect(() => {
		const handleGetTeamSaleWaitingWithdraw = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/monitor/teamSaleWaitingWithdraw`
				);

				const data = response.data.result;
				setTeamSaleData(data);
				setFilteredData(data);

				// Calculate the total waitingWithdraw value
				const total = data.reduce((sum, data) => {
					return sum + parseFloat(data.waitingWithdraw) || 0;
				}, 0);

				// Set the total sum to the state
				setTotalWaitingWithdraw(total);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast({
					title: "Error Fetching Data",
					description: error.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		};
		handleGetTeamSaleWaitingWithdraw();
	}, [toast]);

	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		// Filter data based on query from all columns
		const filtered = teamSaleData.filter((data) => {
			return Object.values(data).join(" ").toLowerCase().includes(query);
		});
		setFilteredData(filtered);
	};

	const handleSort = (key) => {
		let direction = "ascending";
		if (sortConfig.key === key && sortConfig.direction === "ascending") {
			direction = "descending";
		}
		setSortConfig({ key, direction });

		const sortedData = [...filteredData].sort((a, b) => {
			if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
			if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
			return 0;
		});
		setFilteredData(sortedData);
	};

	const WithdrawSuccess = async (id) => {
		Swal.fire({
			text: "ยืนยัน",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: { popup: "swal2-noanimation" },
			hideClass: { popup: "" },
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/monitor/update_withdraw_success`,
						{ id }
					);

					if (response.data.message === "success") {
						toast({
							title: "Success",
							status: "success",
							duration: 5000,
							isClosable: true,
						});
						const updatedData = teamSaleData.map((data) =>
							data.id === id ? { ...data, waitingWithdraw: 0 } : data
						);
						setTeamSaleData(updatedData);
						setFilteredData(updatedData);

						const newTotal = updatedData.reduce((sum, data) => {
							return sum + parseFloat(data.waitingWithdraw) || 0;
						}, 0);
						setTotalWaitingWithdraw(newTotal);
					} else {
						toast({
							title: "Error",
							description: response.data.message,
							status: "warning",
							duration: 5000,
							isClosable: true,
						});
					}
				} catch (error) {
					console.error("Error fetching data:", error);
					toast({
						title: "Error Fetching Data",
						description: error.message,
						status: "error",
						duration: 5000,
						isClosable: true,
					});
				}
			}
		});
	};

	return (
		<>
			<Text fontSize="xl" fontWeight="bold" mb={4}>
				Total Waiting Withdraw: {totalWaitingWithdraw.toFixed(2)}
			</Text>
			<Input
				placeholder="Search across all columns"
				value={searchQuery}
				onChange={handleSearch}
				mb={4}
			/>
			<TableContainer>
				<Table size={"sm"}>
					<Thead>
						<Tr>
							<Th onClick={() => handleSort("first_name")}>Name</Th>
							<Th onClick={() => handleSort("email")}>Email</Th>
							<Th onClick={() => handleSort("referral_code")}>References</Th>
							<Th onClick={() => handleSort("bank")}>Bank</Th>
							<Th onClick={() => handleSort("account_name")}>Account Name</Th>
							<Th onClick={() => handleSort("account_number")}>
								Account Number
							</Th>
							<Th onClick={() => handleSort("underRegister")}>Register</Th>
							<Th onClick={() => handleSort("waitingWithdraw")}>Withdraw</Th>
							<Th isNumeric></Th>
						</Tr>
					</Thead>
					<Tbody>
						{filteredData.map((data, index) => (
							<Tr key={index} color={"gray"} fontSize={"sm"}>
								<Td>
									{data.first_name} {data.last_name}
								</Td>
								<Td>{data.email}</Td>
								<Td>{data.referral_code}</Td>
								<Td>{data.bank}</Td>
								<Td>{data.account_name}</Td>
								<Td>{data.account_number}</Td>
								<Td textAlign={"center"}>{data.underRegister}</Td>
								<Td textAlign={"center"}>{data.waitingWithdraw}</Td>
								<Td isNumeric>
									<Button
										colorScheme="teal"
										size="xs"
										onClick={() => WithdrawSuccess(data.id)}
									>
										ถอนเงิน
									</Button>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
};

const UserRegister = ({ toast }) => {
	const [userData, setUserData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [totalUsers, setTotalUsers] = useState(0);
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: "ascending",
	});

	useEffect(() => {
		const handleGetUsers = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/monitor/all_user_list`
				);
				const data = response.data;
				setUserData(data);
				setFilteredData(data); // ใช้ข้อมูลที่ได้สำหรับการกรอง
				setTotalUsers(data.length);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast({
					title: "Error Fetching Data",
					description: error.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		};
		handleGetUsers();
	}, [toast]);

	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		const filtered = userData.filter((user) =>
			Object.values(user).some((value) =>
				String(value).toLowerCase().includes(query)
			)
		);
		setFilteredData(filtered);
	};

	const handleSort = (key) => {
		let direction = "ascending";
		if (sortConfig.key === key && sortConfig.direction === "ascending") {
			direction = "descending";
		}
		setSortConfig({ key, direction });

		const sortedData = [...filteredData].sort((a, b) => {
			if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
			if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
			return 0;
		});
		setFilteredData(sortedData);
	};

	return (
		<>
			<Text fontSize="xl" fontWeight="bold" mb={4}>
				Total Users: {totalUsers}
			</Text>
			<Input
				placeholder="Search by any field"
				value={searchQuery}
				onChange={handleSearch}
				mb={4}
			/>
			<TableContainer>
				<Table size={"sm"}>
					<Thead>
						<Tr>
							<Th onClick={() => handleSort("id")}>ID</Th>
							<Th onClick={() => handleSort("first_name")}>Full Name</Th>
							<Th onClick={() => handleSort("email")}>Email</Th>
							<Th onClick={() => handleSort("created_datetime")}>Created At</Th>
							<Th onClick={() => handleSort("last_day")}>Last Day</Th>
							<Th>Remaining</Th>
							<Th onClick={() => handleSort("date_before_payment")}>
								Date Before Payment
							</Th>
							<Th onClick={() => handleSort("team_sale_id")}>(id)team sale</Th>
						</Tr>
					</Thead>
					<Tbody>
						{filteredData.map((user, index) => (
							<Tr key={index} color={"gray"} fontSize={"sm"}>
								<Td>{index + 1}</Td>
								<Td>
									{user.first_name} {user.last_name}
								</Td>
								<Td>{user.email}</Td>
								<Td>{new Date(user.created_datetime).toLocaleDateString()}</Td>
								<Td>{new Date(user.last_day).toLocaleDateString()}</Td>
								<Td>
									{(() => {
										const currentDate = new Date();
										const lastDay = new Date(user.last_day);
										const timeDifference = lastDay - currentDate;

										const remainingDays = Math.max(
											0,
											Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
										);
										return remainingDays;
									})()}
								</Td>
								<Td>
									{user.date_before_payment === null
										? "-"
										: new Date(user.date_before_payment).toLocaleDateString()}
								</Td>
								<Td>
									({user.tid}){user.tfn} {user.tln}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
};

export default Detail;
