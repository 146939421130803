import React from "react";
import {
	Container,
	Accordion,
	Flex,
	Text,
	Box,
	Spacer,
	Link,
	SimpleGrid,
	Card,
} from "@chakra-ui/react";
import manual_pdf from "../../file/Manual.pdf";
import { useAuth } from "../../hooks/AuthProvider";

function Manual() {
	const auth = useAuth();
	const bType = auth.user.business_type;

	return (
		<>
			<Flex display={"none"}>
				<Link href={manual_pdf} isExternal>
					คู่มือ
				</Link>
			</Flex>
			<Container
				mt={{ base: "auto", md: 3 }}
				maxW={"6xl"}
				px={{ base: 0, md: 3 }}
			>
				<Card borderRadius={"xl"} p={{ base: 3, md: 7 }}>
					<Accordion allowToggle>
						<Box
							color={"cyan.700"}
							fontWeight={"bold"}
							fontSize={"lg"}
							textAlign={"center"}
						>
							แอดมิน
						</Box>
						<SimpleGrid
							px={{ base: 2, md: 3, lg: 7 }}
							py={{ base: 1, md: 2, lg: 3 }}
							columns={{ base: 1, md: 2, xl: 3 }}
							spacing={{ base: 2, md: 3, xl: 5 }}
						>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								1. เพิ่มหมวดหมู่สินค้าหรือบริการ
							</Box>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								2. เพิ่มสินค้าหรือบริการ
							</Box>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								3. เพิ่มรายชื่อ{bType === "hotel" ? "ห้อง" : "โต๊ะ"}
							</Box>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								4. ปรับแต่งข้อมูลรายละเอียด
								{bType === "hotel" ? "ที่พัก" : "ร้าน"}
							</Box>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								5. ดาวน์โหลด QR Menu และนำไปติดตั้ง
							</Box>
						</SimpleGrid>
					</Accordion>
				</Card>
				<Card
					borderRadius={"xl"}
					p={{ base: 3, md: 7 }}
					mt={{ base: 2, md: 5 }}
				>
					<Accordion allowToggle>
						<Box
							color={"cyan.700"}
							fontWeight={"bold"}
							fontSize={"lg"}
							textAlign={"center"}
						>
							พนักงาน
						</Box>
						<SimpleGrid
							px={{ base: 2, md: 3, lg: 7 }}
							py={{ base: 1, md: 2, lg: 3 }}
							columns={{ base: 1, md: 2, xl: 3 }}
							spacing={{ base: 2, md: 3, xl: 5 }}
						>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								1. ติดตั้งแอพพลิเคชั่นและเปิดการแจ้งเตือน
							</Box>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								2. ลงชื่อเข้าใช้งาน
							</Box>
							<Box
								bgColor={"orange.200"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								3. ฟังก์ชั่นการทำงาน
							</Box>
							<Box
								bgColor={"gray.100"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								&#x2022; แจ้งเตือนออเดอร์
							</Box>
							<Box
								bgColor={"gray.100"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								&#x2022; แสดงรายการสั่งซื้อ
							</Box>
							<Box
								bgColor={"gray.100"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								&#x2022; เพิ่มออเดอร์
							</Box>
							<Box
								bgColor={"gray.100"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								&#x2022; เช็คยอดสั่งซื้อรวม
							</Box>
							<Box
								bgColor={"gray.100"}
								boxShadow={"base"}
								minH={{ base: "3", md: "4em" }}
								py={2}
								px={3}
								borderRadius={"xl"}
							>
								&#x2022; เคลียร์{bType === "hotel" ? "ห้อง" : "โต๊ะ"}
								เพื่อรองรับลูกค้าใหม่
							</Box>
						</SimpleGrid>
					</Accordion>
				</Card>

				<Flex
					direction={{ base: "column", md: "row" }}
					mt={{ base: 2, md: 5 }}
					p={5}
					bgColor={"cyan.700"}
					borderRadius={"xl"}
					color={"white"}
				>
					<Text textAlign={{ base: "center", md: "left" }}>
						หากมีข้อสงสัยติดต่อสอบถามเพิ่มเติมได้ที่{" "}
					</Text>
					<Spacer />
					<Box
						gap={4}
						textAlign={{ base: "center", md: "right" }}
						fontSize={"sm"}
					>
						<Text>sevscan.info@gmail.com </Text>
						<Text>Line : @sevscan</Text>
					</Box>
				</Flex>
			</Container>
		</>
	);
}

export default Manual;
