import React, { useState } from "react";
import * as QRCode from "qrcode";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	SimpleGrid,
	useDisclosure,
	Image as ImageQRcode,
	Box,
} from "@chakra-ui/react";
import imgLogo from "../../images/logo.png";
import exQrcodeOnly from "../../images/ex_qrcode/only.png";
import exQrcodeSquare from "../../images/ex_qrcode/square.png";
import exQrcodeCircle from "../../images/ex_qrcode/circle.png";

const DownloadQRCodesButton = ({ dataArray, shopCode, shopName }) => {
	// console.log("jjj", dataArray, shopCode, shopName);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedOption, setSelectedOption] = useState("qrOnly");
	const { isOpen, onOpen, onClose } = useDisclosure();

	const encodeUnit = (id) => {
		const combined = `${id}mySevscanEncodeDecode`;
		return btoa(combined);
	};

	const createQRCodeWithText = async (url, unitName) => {
		const canvas = document.createElement("canvas");
		await QRCode.toCanvas(canvas, url, { width: 600 });

		if (selectedOption === "qrOnly") {
			// สำหรับตัวเลือก QR Code เท่านั้น
			return new Promise((resolve) => canvas.toBlob(resolve));
		} else if (selectedOption === "square") {
			// ขนาด QR Code
			const qrWidth = canvas.width;
			const qrHeight = canvas.height;
			const textHeight = 20;

			// เพิ่มพื้นที่สำหรับข้อความบนและล่าง
			const finalCanvas = document.createElement("canvas");
			finalCanvas.width = qrWidth;
			finalCanvas.height = textHeight * 13 + qrHeight; // เพิ่มพื้นที่สำหรับข้อความเพิ่มเติม

			const finalContext = finalCanvas.getContext("2d");
			finalContext.fillStyle = "white";
			finalContext.fillRect(0, 0, finalCanvas.width, finalCanvas.height);

			// วาด QR Code ตรงกลาง
			finalContext.drawImage(
				canvas,
				(finalCanvas.width - qrWidth) / 2,
				textHeight * 5
			);

			// เพิ่มชื่อร้านด้านบน
			finalContext.font = "normal 26px 'Noto Sans Thai'";
			finalContext.fillStyle = "black";
			finalContext.textAlign = "center";
			finalContext.fillText(shopName, finalCanvas.width / 2, textHeight * 3);

			// เพิ่มชื่อโต๊ะในบรรทัดที่สอง
			finalContext.font = "bold 32px 'Noto Sans Thai'";
			finalContext.fillText(unitName, finalCanvas.width / 2, textHeight * 5);

			// เพิ่มข้อความ "สแกนสั่ง" ใต้ QR Code
			finalContext.font = "bold 40px 'Noto Sans Thai'";
			finalContext.fillStyle = "black";
			finalContext.fillText(
				"สแกนสั่งซื้อ",
				finalCanvas.width / 2,
				textHeight * 6 + qrHeight
			);

			// โหลดโลโก้และเพิ่มด้านหลังข้อความ
			const logo = new Image();
			logo.src = imgLogo; // URL หรือ path ของโลโก้

			const logoSize = 40; // กำหนดขนาดโลโก้
			await new Promise((resolve) => {
				logo.onload = () => {
					finalContext.drawImage(
						logo,
						finalCanvas.width / 2 - logoSize / 2, // ตำแหน่งโลโก้อยู่ตรงกลาง
						textHeight * 8 + qrHeight, // ปรับตำแหน่งให้อยู่ด้านหลังข้อความ
						logoSize,
						logoSize
					);
					resolve();
				};
			});

			// เพิ่มชื่อเว็บ "www.sevscan.com" (วาดทับโลโก้)
			finalContext.font = "20px 'Noto Sans Thai'";
			finalContext.fillStyle = "gray";
			finalContext.fillText(
				"www.sevscan.com",
				finalCanvas.width / 2,
				textHeight * 9 + qrHeight + logoSize
			);

			// แปลง canvas เป็น Blob
			return new Promise((resolve) => {
				finalCanvas.toBlob(resolve);
			});
		} else {
			// สำหรับตัวเลือกวงกลม (canvas ทั้งหมด)
			const size = canvas.width; // ใช้ขนาดเท่ากับ QR Code
			const circleCanvas = document.createElement("canvas");
			circleCanvas.width = size;
			circleCanvas.height = size;
			const textHeight = 20;

			const circleContext = circleCanvas.getContext("2d");

			// กำหนดพื้นที่ให้เป็นวงกลม
			circleContext.beginPath();
			circleContext.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
			circleContext.closePath();
			circleContext.clip();
			circleContext.fillStyle = "white";
			circleContext.fillRect(0, 0, circleCanvas.width, circleCanvas.height);

			// วาด QR Code ลงใน canvas ที่ถูกตัด
			circleContext.drawImage(canvas, size / 4, size / 4, size / 2, size / 2);

			// เพิ่มชื่อร้านด้านบน
			circleContext.font = "normal 26px 'Noto Sans Thai'";
			circleContext.fillStyle = "black";
			circleContext.textAlign = "center";
			circleContext.fillText(shopName, circleCanvas.width / 2, textHeight * 5);

			// เพิ่มชื่อโต๊ะในบรรทัดที่สอง
			circleContext.font = "bold 32px 'Noto Sans Thai'";
			circleContext.fillText(unitName, circleCanvas.width / 2, textHeight * 7);

			// เพิ่มข้อความ "สแกนสั่ง" ใต้ QR Code
			circleContext.font = "normal 32px 'Noto Sans Thai'";
			circleContext.fillStyle = "black";
			circleContext.fillText(
				"สแกนสั่งซื้อ",
				circleCanvas.width / 2,
				textHeight * 8.5 + size / 2
			);

			// โหลดโลโก้และเพิ่มด้านหลังข้อความ
			const logo = new Image();
			logo.src = imgLogo; // URL หรือ path ของโลโก้

			const logoSize = 30; // กำหนดขนาดโลโก้
			await new Promise((resolve) => {
				logo.onload = () => {
					circleContext.drawImage(
						logo,
						circleCanvas.width / 2 - logoSize / 2, // ตำแหน่งโลโก้อยู่ตรงกลาง
						textHeight * 10 + size / 2, // ปรับตำแหน่งให้อยู่ด้านหลังข้อความ
						logoSize,
						logoSize
					);
					resolve();
				};
			});

			// เพิ่มชื่อเว็บ "www.sevscan.com" (วาดทับโลโก้)
			circleContext.font = "20px 'Noto Sans Thai'";
			circleContext.fillStyle = "gray";
			circleContext.fillText(
				"www.sevscan.com",
				circleCanvas.width / 2,
				textHeight * 11 + size / 2 + logoSize
			);

			// แปลง canvas เป็น Blob
			return new Promise((resolve) => circleCanvas.toBlob(resolve));
		}
	};

	const handleDownload = async () => {
		setIsLoading(true);

		try {
			if (!dataArray || dataArray.length === 0) {
				console.error("dataArray is empty or undefined");
				setIsLoading(false);
				return;
			}

			const zip = new JSZip();

			// สำหรับ QR Code เมนู
			const newQrCodeBlob = await createQRCodeWithText(
				`${window.location.origin}/demo/${shopCode}`,
				"ดูได้อย่างเดียว"
			);
			zip.file(`ดูได้อย่างเดียว.png`, newQrCodeBlob);

			// สำหรับ QR Code ของแต่ละ unit
			for (let i = 0; i < dataArray.length; i++) {
				const urlLink = `${
					window.location.origin
				}/menu?shop=${shopCode}&unit=${encodeUnit(dataArray[i].id)}`;
				const qrCodeBlob = await createQRCodeWithText(
					urlLink,
					dataArray[i].name
				);
				zip.file(`${dataArray[i].name}.png`, qrCodeBlob);
			}

			const content = await zip.generateAsync({ type: "blob" });
			saveAs(content, "qr_codes.zip");
		} catch (error) {
			console.error("Error generating QR codes and creating ZIP file:", error);
		} finally {
			setIsLoading(false);
			onClose();
		}
	};

	return (
		<>
			<Flex align="center" justify="right" mb={3}>
				<Button
					onClick={onOpen}
					position="fixed"
					bottom={4}
					right={28}
					boxShadow="md"
					zIndex={1}
					borderRadius={"full"}
					border={"1.5px solid"}
					borderColor={"cyan.700"}
					color={"cyan.800"}
					bgColor={"#d0e7ec"}
					w={"12em"}
					fontWeight={"400"}
				>
					ดาวน์โหลด QR Menu
				</Button>
			</Flex>

			{/* Modal เลือกประเภท */}
			<Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>เลือกประเภท QR Code</ModalHeader>
					<ModalBody bg={"gray.200"}>
						<SimpleGrid
							columns={{ base: 2, md: 3 }}
							spacing={{ base: 3, md: 5, xl: 10 }}
						>
							{[
								{ value: "qrOnly", src: exQrcodeOnly },
								{ value: "square", src: exQrcodeSquare },
								{ value: "circle", src: exQrcodeCircle },
							].map((option) => (
								<Box
									key={option.value}
									onClick={() => setSelectedOption(option.value)}
									cursor="pointer"
									borderWidth={2}
									borderRadius="md"
									borderColor={
										selectedOption === option.value ? "blue.500" : "gray.200"
									}
									bg={selectedOption === option.value ? "blue.100" : "gray.200"}
									p={2}
									_hover={{ borderColor: "blue.400", bg: "blue.100" }}
									transition="all 0.2s ease-in-out"
									padding={{ base: 2, md: 5, xl: 10 }}
								>
									<Flex
										alignItems="center"
										justifyContent="center"
										height="100%" // ให้ Flex เต็มความสูงของ Box
									>
										<ImageQRcode
											src={option.src}
											alt={option.value}
											w={"full"}
										/>
									</Flex>
								</Box>
							))}
						</SimpleGrid>
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>
							ยกเลิก
						</Button>
						<Button
							colorScheme="blue"
							onClick={handleDownload}
							isLoading={isLoading}
						>
							ดาวน์โหลด
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default DownloadQRCodesButton;
