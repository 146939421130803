import React from "react";
import Masonry from "react-masonry-css";
import {
	Box,
	Card,
	CardFooter,
	IconButton,
	Image,
	Flex,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import "../../Masonry.css";

import imgPromote1 from "../../images/team_promote/1.png";
import imgPromote2 from "../../images/team_promote/2.png";
import imgPromote3 from "../../images/team_promote/3.png";
import imgPromote4 from "../../images/team_promote/4.png";
import imgPromote5 from "../../images/team_promote/5.png";
import imgPromote6 from "../../images/team_promote/6.png";
import imgPromote7 from "../../images/team_promote/7.png";
import imgPromote8 from "../../images/team_promote/8.png";
import imgPromote9 from "../../images/team_promote/9.png";
import imgPromote10 from "../../images/team_promote/10.png";
import imgPromote11 from "../../images/team_promote/11.png";
import imgPromote12 from "../../images/team_promote/12.png";

const Post = ({ title, image }) => {
	const handleDownload = () => {
		const link = document.createElement("a");
		link.href = image;
		link.download = title;
		link.click();
	};

	return (
		<Box mb={4}>
			<Card
				border="1px"
				borderColor="gray.200"
				boxShadow="sm"
				borderRadius={"xl"}
			>
				<Image src={image} alt={title} borderTopRadius="xl" />
				<CardFooter p={1}>
					<Flex width="100%" justifyContent="center">
						<IconButton
							icon={<DownloadIcon />}
							onClick={handleDownload}
							aria-label="Download image"
							size="sm"
							variant="ghost"
						/>
					</Flex>
				</CardFooter>
			</Card>
		</Box>
	);
};

const MasonryLayout = ({ posts }) => {
	const breakpointColumnsObj = {
		default: 6,
		1400: 3,
		1100: 2,
		700: 2,
	};

	return (
		<Masonry
			breakpointCols={breakpointColumnsObj}
			className="masonry-grid"
			columnClassName="masonry-grid_column"
		>
			{posts.map((post, index) => (
				<Post key={index} {...post} />
			))}
		</Masonry>
	);
};

function Promote() {
	const postsData = [
		{ title: "sevscan_promote_1", image: imgPromote1 },
		{ title: "sevscan_promote_2", image: imgPromote2 },
		{ title: "sevscan_promote_3", image: imgPromote3 },
		{ title: "sevscan_promote_4", image: imgPromote4 },
		{ title: "sevscan_promote_5", image: imgPromote5 },
		{ title: "sevscan_promote_6", image: imgPromote6 },
		{ title: "sevscan_promote_7", image: imgPromote7 },
		{ title: "sevscan_promote_8", image: imgPromote8 },
		{ title: "sevscan_promote_9", image: imgPromote9 },
		{ title: "sevscan_promote_10", image: imgPromote10 },
		{ title: "sevscan_promote_11", image: imgPromote11 },
		{ title: "sevscan_promote_12", image: imgPromote12 },
	];

	return (
		<Box>
			<MasonryLayout posts={postsData} />
		</Box>
	);
}

export default Promote;
