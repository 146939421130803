import React, { useState } from "react";
import axios from "axios";
import { Flex, Box, Button, Input, useToast } from "@chakra-ui/react";
import { API_BASE_URL } from "../config/apiConfig";
import Detail from "./page/Detail";

const Monitor = () => {
	const toast = useToast();
	const [adminLogin, setAdminLogin] = useState("");
	const [loginSuccess, setLoginSuccess] = useState(0);

	const handleSubmit = async () => {
		try {
			const response = await axios.post(`${API_BASE_URL}/monitor/admin_login`, {
				adminLogin,
			});

			// console.log(response);

			const data = response.data;

			if (data.message === "success") {
				setLoginSuccess(1);
			} else {
				toast({
					title: "Login Failed",
					description: data.message || "An error occurred during login.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error("Error:", error);
			toast({
				title: "Error Checking Connection",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			{!loginSuccess ? (
				<Flex height={"100vh"} alignItems="center" justifyContent="center">
					<Box
						p={5}
						borderWidth={1}
						borderRadius="md"
						boxShadow="sm"
						textAlign={"center"}
					>
						<Input
							type="text"
							placeholder="Admin Key"
							textAlign={"center"}
							value={adminLogin}
							onChange={(e) => setAdminLogin(e.target.value)}
							mb={3}
						/>
						<Button onClick={handleSubmit} colorScheme="teal">
							LOGIN
						</Button>
					</Box>
				</Flex>
			) : (
				<Detail />
			)}
		</>
	);
};

export default Monitor;
