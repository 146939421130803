import React, { useContext, useEffect, useMemo, useState } from "react";
import {
	Box,
	ScaleFade,
	Flex,
	SimpleGrid,
	Stat,
	StatLabel,
	StatNumber,
	useColorModeValue,
	Grid,
	Badge,
	Text,
	Spacer,
	Spinner,
	Button,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import { PiNewspaperDuotone, PiMoneyDuotone } from "react-icons/pi";
import HistoryOrder from "../component/HistoryOrder";
import BarChartIncome from "../component/BarChartIncome";
import ShopContext from "../../hooks/ShopContext";

function Dashboard() {
	const { shopValueId } = useContext(ShopContext);
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [dataSaleOrder, setDataSaleOrder] = useState({
		order_date: "a",
		total_sales: 0,
		total_orders: 0,
	});
	const [dataBarChartIncome, setDataBarChartIncome] = useState();
	useEffect(() => {
		const fetchData = async () => {
			// setLoading(true);
			try {
				const response1 = axios.post(
					`${API_BASE_URL}/api/site/dashboard_sale_order`,
					{ shopValueId },
					{ headers }
				);
				const response2 = axios.post(
					`${API_BASE_URL}/api/site/dashboard_chart_history`,
					{ shopValueId },
					{ headers }
				);

				const [data1, data2] = await axios.all([response1, response2]);
				const res1 = data1.data.results;
				if (res1.length !== 0) {
					res1[0].total_sales = formatValue(res1[0].total_sales);
					setDataSaleOrder(res1[0]);
				}
				setDataBarChartIncome(data2.data.results);
			} catch (error) {
				// console.log("dashboard_sale_order : " + error);
			} finally {
				// setLoading(false);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [headers, shopValueId]);

	const [selectPopular, setSelectPopular] = useState("today");
	const [dataPopular, setDataPopular] = useState();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/dashboard_popular`,
					{ shopValueId, selectPopular },
					{ headers }
				);
				// console.log("popular", response.data.results);
				setDataPopular(response.data.results);
			} catch (error) {
				// console.log("dashboard_popular : " + error);
			} finally {
				setLoading(false);
			}
		};

		if (shopValueId && headers && selectPopular) {
			fetchData();
		}
	}, [headers, shopValueId, selectPopular]);

	const handleButtonClick = (option) => {
		setSelectPopular(option);
	};

	const formatValue = (value) => {
		return value.endsWith(".00") ? parseInt(value) : value;
	};

	return (
		<>
			<Grid
				templateColumns={{ base: "1fr", md: "2fr 2fr", lg: "2fr 1fr" }}
				gap={{ base: 3, lg: 4 }}
			>
				{/* Left grid item (66% width) */}
				<Box>
					<SimpleGrid
						columns={{ base: 1, md: 1, lg: 2 }}
						spacing={{ base: 3, lg: 4 }}
					>
						<ScaleFade initialScale={0.5} in={true}>
							<StatsCard
								title={"ยอดขายวันนี้"}
								stat={`${dataSaleOrder?.total_sales} บาท`}
								icon={<PiMoneyDuotone size={"3em"} color="#0987a0" />}
							/>
						</ScaleFade>
						<ScaleFade initialScale={0.5} in={true}>
							<StatsCard
								title={"สั่งซื้อวันนี้"}
								stat={`${dataSaleOrder.total_orders} ออเดอร์`}
								icon={<PiNewspaperDuotone size={"3em"} color="#0987a0" />}
							/>
						</ScaleFade>
					</SimpleGrid>
					<Box
						mt={{ base: 3, lg: 4 }}
						p={{ base: 5 }}
						borderRadius={"1em"}
						bgColor={useColorModeValue("white", "gray.700")}
					>
						<Text fontWeight={"500"} mb={2}>
							ประวัติยอดขาย
						</Text>
						<BarChartIncome data={dataBarChartIncome} />
					</Box>
					<Box
						mt={{ base: 3, lg: 4 }}
						p={{ base: 5, md: 17 }}
						borderRadius={"1em"}
						bgColor={useColorModeValue("white", "gray.700")}
						minH={"260px"}
					>
						<Flex mb={2}>
							<Text fontWeight={"500"}>ขายดี</Text>
							<Spacer />
							<Flex gap={0.5}>
								<Button
									colorScheme="gray"
									variant={selectPopular === "last_30_days" ? "solid" : "ghost"}
									size="sm"
									w={"6em"}
									fontWeight={"400"}
									onClick={() => handleButtonClick("last_30_days")}
								>
									30 วันล่าสุด
								</Button>
								<Button
									colorScheme="gray"
									variant={selectPopular === "last_7_days" ? "solid" : "ghost"}
									size="sm"
									w={"6em"}
									fontWeight={"400"}
									onClick={() => handleButtonClick("last_7_days")}
								>
									7 วันล่าสุด
								</Button>
								<Button
									colorScheme="gray"
									variant={selectPopular === "today" ? "solid" : "ghost"}
									size="sm"
									w={"5em"}
									fontWeight={"400"}
									onClick={() => handleButtonClick("today")}
								>
									วันนี้
								</Button>
							</Flex>
						</Flex>
						<Grid
							templateColumns={{
								base: "repeat(2, 1fr)",
								md: "repeat(1, 1fr)",
								lg: "repeat(2, 1fr)",
								xl: "repeat(3, 1fr)",
							}}
							gap={3}
						>
							{loading ? (
								<Box
									gridColumn="1 / -1"
									display="flex"
									justifyContent="center"
									alignItems="center"
									minHeight="140px"
								>
									<Spinner size="sm" />
								</Box>
							) : (
								<>
									{dataPopular?.map((product) => (
										<ScaleFade initialScale={0.5} in={true}>
											<Box
												px={4}
												py={1}
												borderRadius={"0.8em"}
												bgColor={"rgba(7, 111, 131, 0.1)"}
												color={"cyan.700"}
												pos={"relative"}
												fontWeight="normal"
												key={product.id}
												minH={"full"}
											>
												<Text fontSize={{ base: "sm", md: "auto" }}>
													{product.name}
												</Text>
												<Text
													fontWeight={"300"}
													color={"gray.500"}
													fontSize={"sm"}
												>
													{formatValue(product?.total_sales)} บาท
												</Text>
												<Badge
													minW={6}
													color={"cyan.700"}
													bgColor={"white"}
													pos={"absolute"}
													bottom="5px"
													right="5px"
													borderRadius={"full"}
													fontSize={"sm"}
													fontWeight="normal"
													textAlign={"center"}
												>
													{product.total_quantity}
												</Badge>
											</Box>
										</ScaleFade>
									))}
								</>
							)}
						</Grid>
					</Box>
				</Box>

				<Box
					p={{ base: 5, md: 17 }}
					borderRadius={"1em"}
					bgColor={useColorModeValue("white", "gray.700")}
				>
					<Text fontWeight={"500"} mb={3}>
						คำสั่งซื้อล่าสุด
					</Text>
					<Box
						maxH={{ base: "fit-content", sm: "calc(100vh - 205px)" }}
						overflowY="auto"
					>
						<ScaleFade initialScale={0.5} in={true}>
							{/* Assuming HistoryOrder is your component for displaying order history */}
							<HistoryOrder />
						</ScaleFade>
					</Box>
				</Box>
			</Grid>
		</>
	);
}

function StatsCard(props) {
	const { title, stat, icon } = props;
	return (
		<Stat
			p={5}
			bgColor={useColorModeValue("white", "gray.700")}
			rounded={"2xl"}
		>
			<Flex justifyContent={"space-between"}>
				<Box>
					<StatLabel fontSize={"md"} fontWeight={"500"} isTruncated>
						{title}
					</StatLabel>
					<StatNumber fontSize={"2xl"} fontWeight={"400"} color={"cyan.800"}>
						{stat}
					</StatNumber>
				</Box>
				<Box
					my={"auto"}
					color={useColorModeValue("gray.800", "gray.200")}
					alignContent={"center"}
				>
					{icon}
				</Box>
			</Flex>
		</Stat>
	);
}

export default Dashboard;
