import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	Image,
	ScaleFade,
	Spinner,
	useToast,
	Text,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { API_BASE_URL } from "../../config/apiConfig";
import imageCompression from "browser-image-compression";
import bgLogo from "../../images/bg_monitor.jpg";

const ImageShopUploader = ({ shopLogo, setShopLogo }) => {
	const toast = useToast();
	const prevImageData = useRef(shopLogo);
	const [previewImage, setPreviewImage] = useState(null);
	const [isLoadingImage, setIsLoadingImage] = useState(false);
	const [updateImage, setUpdateImage] = useState(false);
	const [isFilePickerActive, setIsFilePickerActive] = useState(false);

	const onDrop = async (acceptedFiles) => {
		if (isFilePickerActive) return;

		const droppedImage = acceptedFiles[0];
		if (!droppedImage.type.startsWith("image/")) {
			toast({
				title: "เลือกเฉพาะไฟล์ภาพเท่านั้น",
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		// Options for compression
		const options = {
			maxSizeMB: 1, // Maximum file size in MB
			maxWidthOrHeight: 512, // Maximum width or height of the image
			useWebWorker: true, // Use web worker for performance
		};

		setIsLoadingImage(true);
		try {
			// Compress the image
			const compressedImage = await imageCompression(droppedImage, options);

			// Create a preview and set states with the compressed image
			setUpdateImage(true);
			setShopLogo(compressedImage);
			setPreviewImage(URL.createObjectURL(compressedImage));
			setIsLoadingSaveBtnDownload(false);
			setIsFilePickerActive(false); // Reset after processing
		} catch (error) {
			console.error("Error compressing the image", error);
			toast({
				title: "เกิดข้อผิดพลาดในการบีบอัดภาพ",
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		} finally {
			setIsLoadingImage(false);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/png": [],
			"image/jpeg": [],
			"image/jpg": [],
		},
		multiple: false,
		onFileDialogCancel: () => setIsFilePickerActive(false),
	});

	const [isLoadingSaveBtnDownload, setIsLoadingSaveBtnDownload] =
		useState(false);
	const handleEditClick = () => {
		setShopLogo("");
		setIsLoadingSaveBtnDownload(true);
	};

	useEffect(() => {
		if (!updateImage && shopLogo) {
			prevImageData.current = shopLogo;
			setPreviewImage(`${API_BASE_URL}/image/logo/${shopLogo}`);
		}
	}, [shopLogo, updateImage]);

	useEffect(() => {
		const handleFilePicker = () => setIsFilePickerActive(true);
		const dropzoneElement = document.querySelector('[data-testid="dropzone"]');
		if (dropzoneElement) {
			dropzoneElement.addEventListener("click", handleFilePicker);
		}
		return () => {
			if (dropzoneElement) {
				dropzoneElement.removeEventListener("click", handleFilePicker);
			}
		};
	}, []);

	return (
		<Box
			position="relative"
			align="center"
			justify="center"
			w={"200px"}
			h={"200px"}
		>
			{shopLogo ? (
				<>
					<Box position="relative" w={"200px"} h={"200px"} bgImage={bgLogo}>
						<ScaleFade initialScale={0.5} in={true}>
							<Image src={previewImage} alt="Uploaded" maxH={"200px"} />
						</ScaleFade>
					</Box>
					{!isLoadingSaveBtnDownload && (
						<Box
							py={1.5}
							borderBottomRadius={"md"}
							textAlign="center"
							bgColor={"gray.200"}
							onClick={handleEditClick}
							cursor={"pointer"}
							fontWeight={"bold"}
							boxShadow={"base"}
						>
							<Text>เปลี่ยน</Text>
						</Box>
					)}
				</>
			) : (
				<Box w={"full"} bgColor={"white"}>
					{!isLoadingImage ? (
						<Box
							{...getRootProps()}
							border="1px dashed"
							borderRadius={"md"}
							cursor="pointer"
							h={"200px"}
							w={"200px"}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<input {...getInputProps()} />
							<Text>
								เลือกโลโก้ <br /> พื้นหลังโปร่งใส <br />{" "}
								<small style={{ color: "red" }}>(200x200)</small>
							</Text>
						</Box>
					) : (
						<Spinner color="red.500" mt={100} />
					)}
				</Box>
			)}
		</Box>
	);
};

export default ImageShopUploader;
