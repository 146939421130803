import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import {
	Box,
	Flex,
	Button,
	useColorModeValue,
	Stack,
	useColorMode,
	HStack,
	Image,
	Select,
	useToast,
	Container,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useDisclosure,
	Text,
	Icon,
	IconButton,
	Link,
	SimpleGrid,
} from "@chakra-ui/react";
import {
	MoonIcon,
	SunIcon,
	CloseIcon,
	ChevronRightIcon,
	HamburgerIcon,
} from "@chakra-ui/icons";
import {
	FaTwitter,
	FaFacebook,
	FaInstagram,
	FaYoutube,
	FaWhatsapp,
} from "react-icons/fa";
import { FaLine } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import full_logo from "../images/logo250.png";
import { API_BASE_URL } from "../config/apiConfig";
import ShopContext from "../hooks/ShopContext";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaSignInAlt } from "react-icons/fa";
// import ChangeLanguage from "../mobile/ChangeLanguage";
import ProfilePopover from "./component/ProfilePopover";

const Header = () => {
	const { colorMode, toggleColorMode } = useColorMode();
	const navigate = useNavigate();

	const auth = useAuth();
	const handleHome = () => {
		navigate("/");
	};
	const handleLogin = () => {
		navigate("/login");
	};

	const toast = useToast();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const { setShopId } = useContext(ShopContext);
	const [listShop, setListShop] = useState([]);
	const [selectedShopId, setSelectedShopId] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/list_shop`,
					{},
					{ headers }
				);
				setListShop(response.data.results);
				if (response.data.results.length > 0) {
					// Set selected shop only if it's not already set
					if (!selectedShopId) {
						setSelectedShopId(response.data.results[0].id);
					}
				}
			} catch (error) {
				console.error("Error list_shop:", error);
			}
		};

		// console.log(listShop);
		if (headers.Authorization !== undefined) {
			fetchData();
		}
	}, [toast, headers, selectedShopId]); // Remove selectedShopId from the dependency array of useEffect

	useEffect(() => {
		setShopId(selectedShopId);
	}, [selectedShopId, setShopId]);

	const handleSelectChange = (event) => {
		// Handle select change and update selectedShopId
		setSelectedShopId(event.target.value);
	};

	const { isOpen, onToggle, onClose } = useDisclosure();

	const [isFixed, setIsFixed] = useState(false);

	const handleScroll = () => {
		if (window.scrollY > 30) {
			setIsFixed(true);
		} else {
			setIsFixed(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<Box as="header" bg={useColorModeValue("cyan.700", "gray.800")} py={3}>
				<Container maxW="container.xl">
					<Flex
						justify="space-between"
						align="center"
						wrap="wrap"
						color={"white"}
					>
						<HStack spacing={4} align="center">
							<Flex align="center">
								<Icon as={BiEnvelope} w={5} h={5} me={2} />
								<Link href="mailto:sevscan.info@gmail.com">
									sevscan.info@gmail.com
								</Link>
							</Flex>
							<Flex align="center" display={{ base: "none", lg: "flex" }}>
								<Icon as={FaLine} w={5} h={5} me={2} />
								<Text> @sevscan</Text>
							</Flex>
						</HStack>
						<HStack spacing={4}>
							<Link href="#" isExternal display="none">
								<Icon as={FaTwitter} boxSize={4} />
							</Link>
							<Link href="#" isExternal display="none">
								<Icon as={FaFacebook} boxSize={4} />
							</Link>
							<Link href="#" isExternal display="none">
								<Icon as={FaInstagram} boxSize={4} />
							</Link>
							<Link href="#" isExternal display="none">
								<Icon as={FaYoutube} boxSize={4} />
							</Link>
							<Link href="#" isExternal display="none">
								<Icon as={FaWhatsapp} boxSize={4} />
							</Link>
							<Link href="#" onClick={toggleColorMode} mt={-2}>
								<Icon
									as={colorMode === "light" ? MoonIcon : SunIcon}
									boxSize={4}
								/>
							</Link>

							{/* <ChangeLanguage /> */}
						</HStack>
					</Flex>
				</Container>
			</Box>

			<Box
				bg={useColorModeValue("white", "gray.900")}
				px={{ base: 0, md: 4 }}
				width="100%"
				zIndex={3}
				boxShadow={"0px 0px 4px #0987a047"}
				py={3}
				position={isFixed ? "fixed" : "relative"}
				top={isFixed ? "0" : "auto"}
				transition="position 0.1s ease-in-out"
			>
				<Container maxW="container.xl" px={0}>
					<Flex
						pl={{ base: 1 }}
						pr={{ base: 3 }}
						h={{ base: 12, md: 16 }}
						alignItems={"center"}
						justifyContent={"space-between"}
					>
						<Box>
							<HStack>
								<Flex as={"button"}>
									<IconButton
										onClick={onToggle}
										icon={
											isOpen ? (
												<CloseIcon w={3} h={3} />
											) : (
												<HamburgerIcon w={5} h={5} />
											)
										}
										variant={"ghost"}
										aria-label={"Toggle Navigation"}
										display={{ base: "flex", md: "none" }}
									/>
									<Image
										onClick={handleHome}
										display={{ base: "none", md: "flex" }}
										width={"auto"}
										h={10}
										pt={{ base: "0", md: "0" }}
										src={full_logo}
										alt="sevscan logo"
									/>
								</Flex>
								{listShop.length > 1 && (
									<Select
										// pl={{ base: 2, md: "5.4em" }}
										// pr={2}
										color="cyan.800"
										fontWeight="400"
										fontSize="1em"
										value={selectedShopId}
										onChange={handleSelectChange}
									>
										{listShop.map((option, index) => (
											<option
												key={index}
												value={option.id}
												style={{ whiteSpace: "pre-wrap" }}
											>
												{option.name}
											</option>
										))}
									</Select>
								)}
								<Flex
									display={{ base: "none", md: "flex" }}
									ml={{ base: 2, md: 5, lg: 10 }}
								>
									<DesktopNav />
								</Flex>
							</HStack>
						</Box>

						<Flex alignItems={"center"}>
							<Stack direction={"row"} spacing={0}>
								{auth.user.length !== 0 ? (
									<ProfilePopover />
								) : (
									<>
										<Button
											display={"none"}
											colorScheme="teal"
											variant="ghost"
											leftIcon={<BsPersonFillAdd />}
										>
											สมัครสมาชิก
										</Button>
										<Button
											as={"button"}
											onClick={handleLogin}
											color="#018cc9"
											bg={"gray.50"}
											_hover={{ bg: "gray.100" }}
											_dark={{ bg: "gray.700", _hover: { bg: "gray.600" } }}
											leftIcon={<FaSignInAlt />}
										>
											ลงชื่อเข้าใช้
										</Button>
									</>
								)}
							</Stack>
						</Flex>
					</Flex>
				</Container>

				<MobileNav isOpen={isOpen} onClose={onClose} />
			</Box>
		</>
	);
};

const DesktopNav = () => {
	const popoverContentBgColor = useColorModeValue("white", "gray.800");
	const navigate = useNavigate();
	const location = useLocation();
	const linkColor = useColorModeValue("gray.600", "gray.200");
	const highlightColor = useColorModeValue("cyan.700", "cyan.300");

	return (
		<Stack direction={"row"} spacing={{ base: 1, md: 1, lg: 4 }}>
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Popover trigger={"hover"} placement={"bottom-start"}>
						<PopoverTrigger>
							<Box
								as="button"
								p={2}
								onClick={() => navigate(navItem.href)}
								fontSize={"md"}
								_hover={{
									textDecoration: "none",
									color: highlightColor,
								}}
								fontWeight={location.pathname === navItem.href ? 800 : 500}
								color={
									location.pathname === navItem.href
										? highlightColor
										: linkColor
								}
							>
								{navItem.label}
							</Box>
						</PopoverTrigger>

						{navItem.children && (
							<PopoverContent
								border={0}
								boxShadow={"xl"}
								bg={popoverContentBgColor}
								p={4}
								rounded={"xl"}
								minW={"sm"}
							>
								<Stack>
									{navItem.children.map((child) => (
										<DesktopSubNav key={child.label} {...child} />
									))}
								</Stack>
							</PopoverContent>
						)}
					</Popover>
				</Box>
			))}
		</Stack>
	);
};

const DesktopSubNav = ({ label, href, subLabel }) => {
	const navigate = useNavigate();

	return (
		<Box
			cursor={"pointer"}
			role={"group"}
			display={"block"}
			p={2}
			rounded={"md"}
			_hover={{ bg: useColorModeValue("cyan.50", "gray.900") }}
			onClick={() => navigate(href)}
		>
			<Stack direction={"row"} align={"center"}>
				<Box>
					<Text
						transition={"all .3s ease"}
						_groupHover={{ color: "cyan.400" }}
						fontWeight={500}
					>
						{label}
					</Text>
					<Text fontSize={"sm"} color={"gray.400"}>
						{subLabel}
					</Text>
				</Box>
				<Flex
					transition={"all .3s ease"}
					transform={"translateX(-10px)"}
					opacity={0}
					_groupHover={{ opacity: "100%", transform: "translateX(0)" }}
					justify={"flex-end"}
					align={"center"}
					flex={1}
				>
					<Icon color={"cyan.400"} w={5} h={5} as={ChevronRightIcon} />
				</Flex>
			</Stack>
		</Box>
	);
};

const MobileNav = ({ isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={"center"}>Menu</ModalHeader>
				<ModalBody>
					<SimpleGrid
						columns={{ base: 1, sm: 2 }}
						spacing={4}
						bg={useColorModeValue("white", "gray.800")}
						p={4}
						pt={0}
						pb={6}
					>
						{NAV_ITEMS.map((navItem) => (
							<MobileNavItem
								key={navItem.label}
								{...navItem}
								onClose={onClose}
							/>
						))}
					</SimpleGrid>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

const MobileNavItem = ({ label, href, onClose }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const linkColor = useColorModeValue("gray.600", "gray.200");
	const highlightColor = useColorModeValue("cyan.700", "cyan.300");

	const handleClick = () => {
		navigate(href);
		onClose();
	};

	return (
		<Button
			boxShadow={"0px 0px 1px 0px gray"}
			textAlign={"center"}
			onClick={handleClick}
			bgColor={"white"}
		>
			<Box py={2}>
				<Text
					fontWeight={location.pathname === href ? 800 : 500}
					color={location.pathname === href ? highlightColor : linkColor}
				>
					{label}
				</Text>
			</Box>
		</Button>
	);
};

const NAV_ITEMS = [
	{
		label: "หน้าแรก",
		href: "/",
	},
	// {
	// 	label: "คู่มือการใช้งาน",
	// 	children: [
	// 		{
	// 			label: "ขั้นตอนการนำไปใช้กับร้าน",
	// 			href: "/pmanual",
	// 		},
	// 		{
	// 			label: "ระบบจัดการหลังบ้าน",
	// 			href: "/bmanual",
	// 		},
	// 		{
	// 			label: "การสร้าง Line Notify Token",
	// 			href: "/tmanual",
	// 		},
	// 	],
	// },
	{
		label: "ฟังก์ชั่นทั้งหมด",
		href: "/pmanual",
	},
	{
		label: "ตัวอย่าง QR Menu",
		href: "/qrexample",
	},
	{
		label: "ค่าบริการ",
		href: "/price",
	},
	{
		label: "ติดต่อสอบถาม",
		href: "/contact",
	},
	{
		label: "นโยบายความเป็นส่วนตัว",
		href: "/privacy",
	},
	// {
	// 	label: "คําถามที่พบบ่อย",
	// 	href: "/faq",
	// },
];

export default Header;
