import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
	Box,
	Card,
	Grid,
	Text,
	Button,
	ScaleFade,
	useToast,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	FormControl,
	FormLabel,
	Flex,
	Input,
	Spacer,
	Spinner,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import DownloadQRCodeButton from "../component/DownloadQRCodeButton";

function Unit() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [unitList, setUnitList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [shopCode, setShopCode] = useState();
	const [shopName, setShopName] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				// const response = await axios.post(
				// 	`${API_BASE_URL}/api/site/list_unit`,
				// 	{ shopValueId, page: "unit" },
				// 	{ headers }
				// );
				// // console.log(response.data);
				// setIsLoading(false);
				// setUnitList(response.data.results);

				const response1 = axios.post(
					`${API_BASE_URL}/api/site/list_unit`,
					{ shopValueId, page: "unit" },
					{ headers }
				);
				const response2 = axios.post(
					`${API_BASE_URL}/api/site/shop_detail`,
					{ shopValueId },
					{ headers }
				);
				const [data1, data2] = await axios.all([response1, response2]);
				setIsLoading(false);
				setUnitList(data1.data.results);
				setShopCode(data2.data.results[0].code);
				setShopName(data2.data.results[0].name);
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [editUnit, setEditUnit] = useState(null);

	const handleEdit = (unit) => {
		setEditUnit(unit);
		onOpen();
	};

	const handleSave = async () => {
		const trimmedName = editUnit?.name?.trim();
		const trimmedNameEn = editUnit?.name_en;

		if (!trimmedName) {
			toast({
				title: `กรอกข้อมูลชื่อสถานที่`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		const response = await axios.post(
			`${API_BASE_URL}/api/site/unit/edit`,
			{ editUnit },
			{ headers }
		);
		if (response.data.message === "success") {
			const index = unitList.findIndex((unit) => unit.id === editUnit.id);
			const updatedUnitList = [...unitList];
			updatedUnitList[index].name = trimmedName;
			updatedUnitList[index].name_en = trimmedNameEn;
			setUnitList(updatedUnitList);
			onClose();
			toast({
				title: `เรียบร้อย`,
				position: "top",
				isClosable: true,
				status: "success",
				variant: "top-accent",
				duration: 5000,
			});
		} else {
			console.error("Error unit/edit");
		}
	};

	const reUnitCode = async (unit) => {
		Swal.fire({
			text: `ทำการเปลี่ยนโค๊ตและโค๊ตเดิมจะใช้งานไม่ได้`,
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/api/site/re_unit_code`,
						{ unit },
						{ headers }
					);
					const updatedUnitList = unitList.map((u) => {
						if (u.id === unit.id) {
							return { ...u, code: response.data.newCode, free_status: "0" };
						}
						return u;
					});
					setUnitList(updatedUnitList);
					toast({
						title: `เรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
				} catch (error) {
					console.error("Error re_unit_code:", error);
				}
			}
		});
	};

	const deleteUnit = async (unitId, unit) => {
		// console.log(unit);
		Swal.fire({
			html: `ลบ "${unit.name}" ออก`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await axios.post(
						`${API_BASE_URL}/api/site/unit/delete`,
						{ unitId },
						{ headers }
					);
					const updatedUnitList = unitList.filter((unit) => unit.id !== unitId);
					setUnitList(updatedUnitList);
					toast({
						title: `ลบโต๊ะแล้ว`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 2000,
					});
				} catch (error) {
					console.error("Error unit/delete:", error);
					toast({
						title: `เกิดข้อผิดพลาด`,
						position: "top",
						isClosable: true,
						status: "error",
						variant: "top-accent",
						duration: 2000,
					});
				}
			}
		});
	};

	const addUnitModal = useDisclosure();
	const [newUnitName, setNewUnitName] = useState("");
	const [newUnitNameEn, setNewUnitNameEn] = useState("");

	const handleAddNewUnit = async () => {
		const trimmedName = newUnitName.trim();
		const trimmedNameEn = newUnitNameEn;

		if (!trimmedName) {
			toast({
				title: `กรอกข้อมูลชื่อสถานที่`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/unit/add`,
				{ name: trimmedName, name_en: trimmedNameEn, shopValueId },
				{ headers }
			);

			// console.log(response.data, unitList);
			if (response.data.message === "success") {
				setUnitList([...unitList, response.data.newUnit]);
				addUnitModal.onClose();
				setNewUnitName("");
				setNewUnitNameEn("");
				toast({
					title: `เพิ่มเรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
			}
		} catch (error) {
			console.error("Error unit/add:", error);
			toast({
				title: `เกิดข้อผิดพลาด`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	return (
		<>
			<DownloadQRCodeButton
				dataArray={unitList}
				shopCode={shopCode}
				shopName={shopName}
			/>
			{isLoading ? (
				<Flex
					justifyContent="center"
					alignItems="center"
					height="calc(100vh - 80px)"
				>
					<Spinner
						thickness="6px"
						speed="0.75s"
						emptyColor="gray.200"
						color="cyan.700"
						size="lg"
					/>
				</Flex>
			) : (
				<>
					<Grid
						templateColumns={{
							base: "repeat(2, 1fr)",
							sm: "repeat(2, 1fr)",
							md: "repeat(2, 1fr)",
							lg: "repeat(3, 1fr)",
							xl: "repeat(5, 1fr)",
						}}
						gap={{ base: 2, md: 3 }}
						mb={14}
					>
						{unitList &&
							unitList.map((unit, index) => (
								<ScaleFade initialScale={0.5} in={true} key={index}>
									<Card
										py={3}
										px={4}
										gap={2}
										borderRadius={"1em"}
										boxShadow={"sm"}
										minH={"full"}
									>
										<Box>{unit.name}</Box>
										<Flex>
											<Box color={"gray.500"}>{unit.name_en}</Box>
											<Spacer />
											<Box textAlign={"right"}>
												<EditIcon
													onClick={() => handleEdit(unit)}
													color="cyan.700"
													cursor="pointer"
												/>
												<DeleteIcon
													onClick={() => deleteUnit(unit.id, unit)}
													color="cyan.700"
													cursor="pointer"
													ml={2}
												/>
											</Box>
										</Flex>
										<Flex mb={2} display={"none"}>
											<Box>{unit.code}</Box>
											<Spacer />
											<Box textAlign={"right"}>
												<EditIcon
													onClick={() => reUnitCode(unit)}
													color="cyan.700"
													cursor="pointer"
												/>
											</Box>
										</Flex>
									</Card>
								</ScaleFade>
							))}
					</Grid>
				</>
			)}

			<Button
				onClick={addUnitModal.onOpen}
				position="fixed"
				bottom={4}
				right={4}
				boxShadow="md"
				zIndex={1} // Ensure the button appears above other content
				borderRadius={"full"}
				border={"1.5px solid"}
				borderColor={"cyan.700"}
				color={"cyan.800"}
				bgColor={"#d0e7ec"}
			>
				<Flex align="center">
					<Text fontWeight="400">
						เพิ่ม{auth.user.business_type === "hotel" ? "ห้อง" : "โต๊ะ"}
					</Text>
				</Flex>
			</Button>

			{/* Edit Unit Modal */}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent pt={4}>
					<ModalBody>
						<FormControl>
							<FormLabel>
								<Flex>
									<Text>
										ชื่อ{auth.user.business_type === "hotel" ? "ห้อง" : "โต๊ะ"}
									</Text>
								</Flex>
							</FormLabel>
							<Input
								mb={2}
								placeholder=""
								focusBorderColor="gray.300"
								value={editUnit?.name}
								onChange={(e) =>
									setEditUnit({ ...editUnit, name: e.target.value })
								}
							/>
							<Input
								placeholder=""
								focusBorderColor="gray.300"
								value={editUnit?.name_en}
								onChange={(e) =>
									setEditUnit({ ...editUnit, name_en: e.target.value })
								}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" onClick={onClose} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="blue" onClick={() => handleSave()}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Add Unit Modal */}
			<Modal
				isOpen={addUnitModal.isOpen}
				onClose={addUnitModal.onClose}
				isCentered
			>
				<ModalOverlay />
				<ModalContent pt={4}>
					<ModalBody>
						<FormControl>
							<FormLabel>
								<Flex>
									<Text>
										ชื่อ{auth.user.business_type === "hotel" ? "ห้อง" : "โต๊ะ"}
									</Text>
								</Flex>
							</FormLabel>
							<Input
								mb={2}
								placeholder="ไทย"
								focusBorderColor="gray.300"
								value={newUnitName}
								onChange={(e) => setNewUnitName(e.target.value)}
							/>
							<Input
								placeholder="อังกฤษ"
								focusBorderColor="gray.300"
								value={newUnitNameEn}
								onChange={(e) => setNewUnitNameEn(e.target.value)}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" onClick={addUnitModal.onClose} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="blue" onClick={handleAddNewUnit}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Unit;
