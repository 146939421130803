import React, { useMemo, useEffect, useState, useContext } from "react";
import axios from "axios";
import {
	Box,
	useToast,
	Button,
	FormControl,
	Input,
	Checkbox,
	Flex,
	useColorModeValue,
	Text,
	Stack,
	Textarea,
	InputGroup,
	Image,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import ImageShopUploader from "../component/ImageShopUploader";
import bgImgBox from "../../images/restaurant/res_slide_12.jpg";

function Shop() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [shopCode, setShopCode] = useState();
	const [shopName, setShopName] = useState();
	const [shopNameEn, setShopNameEn] = useState("");
	const [shopRecommend, setShopRecommend] = useState("");
	const [shopRecommendEn, setShopRecommendEn] = useState("");
	const [checkedCallStaff, setCheckedCallStaff] = useState();
	const [shopLogo, setShopLogo] = useState();
	useEffect(() => {
		// console.log(shopValueId);
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_detail`,
					{ shopValueId },
					{ headers }
				);

				// console.log("shop_detail", response.data);
				setShopCode(response.data.results[0].code);
				setShopName(response.data.results[0].name);
				setShopNameEn(response.data.results[0].name_en);
				setShopRecommend(response.data.results[0].recommend);
				setShopRecommendEn(response.data.results[0].recommend_en);
				setCheckedCallStaff(
					response.data.results[0].show_call_staff === 1 ? true : false
				);
				if (response.data.results[0].logo !== "") {
					setShopLogo(response.data.results[0].logo);
				} else {
					setShopLogo("default.png");
				}
			} catch (error) {
				console.error("Error qr_detail and list_unit:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const [editLogo, setEditLogo] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	useEffect(() => {
		const updateShopDetail = async () => {
			// console.log(shopLogo, shopName, checkedCallStaff, shopDetail);
			const trimmedName = shopName.trim();
			const trimmedNameEn = shopNameEn;
			const trimmedRecommend = shopRecommend;
			const trimmedRecommendEn = shopRecommendEn;

			if (!trimmedName) {
				toast({
					title: `กรอกชื่อร้านก่อนบันทึก`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
				return;
			}

			setIsLoadingSave(true);
			try {
				const formData = new FormData();
				formData.append("name", trimmedName);
				formData.append("name_en", trimmedNameEn);
				formData.append("recommend", trimmedRecommend);
				formData.append("recommend_en", trimmedRecommendEn);
				formData.append("checkedCallStaff", checkedCallStaff);
				formData.append("shopValueId", shopValueId);
				if (shopLogo) {
					formData.append("logo", shopLogo);
				}

				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_update`,
					formData,
					{ headers }
				);
				if (response.data.message === "success") {
					toast({
						title: `อัพเดทเรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
					setIsLoadingSave(false);
					setEditLogo(false);
				} else {
					console.error("Error shop detial/update");
				}
			} catch (error) {
				console.error("Error saving shop detail:", error);
			}
		};

		if (editLogo) {
			updateShopDetail();
		}
	}, [
		shopLogo,
		editLogo,
		setEditLogo,
		checkedCallStaff,
		headers,
		shopName,
		shopNameEn,
		shopRecommend,
		shopRecommendEn,
		shopValueId,
		toast,
	]);

	const handleChange = () => {
		setCheckedCallStaff(!checkedCallStaff);
	};

	return (
		<>
			<Flex
				direction={{ base: "column", lg: "row" }}
				gap={5}
				p={{ base: "auto", md: 3 }}
			>
				<Box flex={{ lg: 8, xl: 9 }}>
					{/* <Box
						bgColor={useColorModeValue("white", "gray.600")}
						borderTopRadius={"1em"}
						borderBottomRadius={"1em"}
						pt={{ base: 7, md: 10 }}
						pb={{ base: 5, md: 7 }}
						display="flex"
						justifyContent="center"
						alignItems="center"
						overflow={"hidden"}
						bgImage={bgImgBox}
						bgSize="cover"
						bgPosition="center"
						bgRepeat="no-repeat"
						backdropFilter="blur(10px)"
					>
						<Image src={bgImgBox} w={"full"} />
						<Box
							w={"200px"}
							h={"236px"}
							boxShadow={"base"}
							borderRadius={"md"}
							overflow="hidden"
						>
							<ImageShopUploader
								shopLogo={shopLogo}
								setShopLogo={setShopLogo}
							/>
						</Box>
					</Box> */}

					<Box
						position="relative" // กำหนดให้รองรับภาพพื้นหลัง
						borderTopRadius="1em"
						pt={{ base: 7, md: 10 }}
						pb={{ base: 5, md: 7 }}
						display="flex" // ใช้ Flexbox
						justifyContent="center" // จัดให้อยู่ตรงกลางแนวนอน
						alignItems="center" // จัดให้อยู่ตรงกลางแนวตั้ง
						overflow="hidden"
						minH={"300px"}
					>
						{/* ภาพพื้นหลังที่มี Blur */}
						<Image
							src={bgImgBox}
							alt="Background"
							position="absolute" // ทำให้ภาพอยู่ด้านหลัง
							top="0"
							left="0"
							width="100%"
							height="100%"
							objectFit="cover"
							filter="blur(3px)" // ใช้ CSS blur
							zIndex={1} // วางไว้ด้านล่างสุด
						/>

						{/* กล่องเนื้อหาที่อยู่ตรงกลาง */}
						<Box
							w="200px"
							h="236px"
							boxShadow="base"
							borderRadius="md"
							overflow="hidden"
							bg="white" // สีพื้นหลังของกล่อง
							zIndex={2} // วางไว้ด้านบน
							position={"absolute"}
							left={"50%"}
							top={"50%"}
							transform="translate(-50%, -50%)"
						>
							<ImageShopUploader
								shopLogo={shopLogo}
								setShopLogo={setShopLogo}
							/>
						</Box>
					</Box>

					<Box
						bgColor={useColorModeValue("white", "gray.600")}
						borderBottomRadius={"1em"}
						px={{ base: 5, md: 10 }}
						pt={{ base: 5, md: 10 }}
						pb={{ base: 4, md: 8 }}
					>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={6}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									ลิ้งค์แสดงเมนูตัวอย่าง
								</Text>
							</FormControl>
							<FormControl>
								<Box bgColor={"gray.100"} px={4} py={2} borderRadius={"md"}>
									{shopCode ? (
										`https://sevscan.com/demo/${shopCode}`
									) : (
										<BeatLoader size={8} color="gray" />
									)}
								</Box>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={5}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									ชื่อร้านหรือกิจการ
								</Text>
							</FormControl>
							<FormControl>
								<Flex
									gap={{ base: 0, xl: 3 }}
									direction={{
										base: "column",
										xl: "row",
									}}
								>
									<Input
										mb={2}
										type="text"
										placeholder="ไทย"
										value={shopName}
										onChange={(e) => setShopName(e.target.value)}
										bgColor={"white"}
										focusBorderColor="gray.300"
									/>
									<Input
										type="text"
										placeholder="อังกฤษ"
										value={shopNameEn}
										onChange={(e) => setShopNameEn(e.target.value)}
										bgColor={"white"}
										focusBorderColor="gray.300"
									/>
								</Flex>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={5}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									ป้ายกำกับ <br />{" "}
									<Box
										fontSize={"sm"}
										fontWeight={"thin"}
										cursor={"pointer"}
										color={"gray.500"}
										onClick={() => alert("HI")}
										display={"none"}
									>
										ตัวอย่าง
									</Box>
								</Text>
							</FormControl>
							<FormControl>
								<Flex
									gap={{ base: 0, xl: 3 }}
									direction={{
										base: "column",
										xl: "row",
									}}
								>
									<Textarea
										mb={2}
										placeholder={`ไทย`}
										value={shopRecommend}
										onChange={(e) => setShopRecommend(e.target.value)}
										bgColor={"white"}
										focusBorderColor="gray.300"
									/>
									<Textarea
										mb={2}
										placeholder={`อังกฤษ`}
										value={shopRecommendEn}
										onChange={(e) => setShopRecommendEn(e.target.value)}
										bgColor={"white"}
										focusBorderColor="gray.300"
									/>
								</Flex>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={5}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} color={"cyan.700"}>
									ปุ่มเรียกพนักงาน
								</Text>
							</FormControl>
							<FormControl>
								<InputGroup
									alignItems="center"
									bgColor={"white"}
									border={"1px solid"}
									borderColor={"gray.200"}
									_hover={{ borderColor: "gray.300" }}
									px={4}
									py={2}
									borderRadius={"md"}
									onClick={() => setCheckedCallStaff(!checkedCallStaff)}
									cursor={"pointer"}
								>
									<Checkbox
										isChecked={checkedCallStaff}
										onChange={handleChange}
										size="lg"
										mr={2}
									/>
									<Text>แสดงที่หน้าเลือกซื้อสินค้าของลูกค้า</Text>
								</InputGroup>
							</FormControl>
						</Stack>
						<Box textAlign={"right"} pt={2}>
							<Button
								w={{ base: "full", md: "15em" }}
								borderRadius={"full"}
								border={"1.5px solid"}
								borderColor={"cyan.700"}
								color={"cyan.800"}
								bgColor={"#d0e7ec"}
								isLoading={isLoadingSave}
								onClick={() => setEditLogo(true)}
								spinner={<BeatLoader size={8} color="#0987a0" />}
							>
								บันทึก
							</Button>
						</Box>
					</Box>
				</Box>
			</Flex>
		</>
	);
}

export default Shop;
