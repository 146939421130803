import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import socket from "../config/socket";
import {
	useToast,
	Stack,
	Center,
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Heading,
	Image,
	ScaleFade,
	Spacer,
	Text,
	IconButton,
} from "@chakra-ui/react";
import { IoLogOut } from "react-icons/io5";
import { BeatLoader } from "react-spinners";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../config/apiConfig";
import ImageLogo from "../images/logo2line.png";
import ImageBg from "../images/bg_monitor.jpg";
import imageLoginPublic from "../images/login-public.jpg";
import EmployeeDisplay from "../components/component/employee/EmployeeDisplay";

function MobileApp() {
	const toast = useToast();
	const [restaurantKey, setRestaurantKey] = useState("");
	const [employeeKey, setEmployeeKey] = useState("");
	const [checkDisplay, setCheckDisplay] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [shopData, setShopData] = useState([]);
	const [checkErrorConnection, setCheckErrorConnection] = useState(false);

	const [deviceInfo, setDeviceInfo] = useState();

	useEffect(() => {
		window.receiveDeviceData = (data) => {
			console.log("Received device data from WebView:", data);
			setDeviceInfo(data); // เก็บข้อมูลลงใน state
		};
		// setDeviceInfo({
		// 	expoPushToken: "ExponentPushToken[8ZIgNnEW7blhMJqCQLtiLV]",
		// 	brand: "xiaomi",
		// 	model: "redmi 4x",
		// });
	}, []);

	useEffect(() => {
		const checkEmployeeLogin = async () => {
			if (localStorage.getItem("mobile_login")) {
				const old_code = JSON.parse(localStorage.getItem("mobile_login"));
				setRestaurantKey(old_code.restaurantKey);
				setEmployeeKey(old_code.employeeKey);
				// console.log(old_code);

				try {
					const response = await axios.post(
						`${API_BASE_URL}/mobile/mobile_login`,
						{
							trimmedRestaurantKey: old_code.restaurantKey.trim(),
							trimmedEmployeeKey: old_code.employeeKey.trim(),
							deviceInfo,
						}
					);

					if (response.data.message === "success") {
						setCheckDisplay(1);
						setShopData(response.data);
					} else {
						setCheckDisplay(2);
					}
				} catch (error) {
					setCheckErrorConnection(true);
				}
			} else {
				setCheckDisplay(2);
			}
		};

		if (deviceInfo) {
			setTimeout(() => {
				checkEmployeeLogin();
			}, 1000);
		}
	}, [deviceInfo]);

	const handleSubmit = async () => {
		const trimmedRestaurantKey = restaurantKey?.trim();
		const trimmedEmployeeKey = employeeKey?.trim();
		// console.log(trimmedRestaurantKey, trimmedEmployeeKey);

		if (!trimmedRestaurantKey || !trimmedEmployeeKey) {
			toast({
				title: `กรอกข้อมูลให้ครบ`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		setIsLoadingSave(true);
		try {
			const response = await axios.post(
				`${API_BASE_URL}/mobile/mobile_login`,
				{
					trimmedRestaurantKey,
					trimmedEmployeeKey,
					deviceInfo,
				},
				{}
			);
			// console.log("hi", response.data);
			if (response.data.message === "success") {
				setCheckDisplay(1);
				setShopData(response.data);
				localStorage.setItem(
					"mobile_login",
					JSON.stringify({
						restaurantKey: trimmedRestaurantKey,
						employeeKey: trimmedEmployeeKey,
					})
				);
			} else {
				toast({
					title: `ข้อมูลไม่ถูกต้อง`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
			setIsLoadingSave(false);
		} catch (error) {
			setIsLoadingSave(false);
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	const handleLogout = () => {
		Swal.fire({
			text: "ออกจากระบบ",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showLoaderOnConfirm: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
			preConfirm: async () => {
				const response = await axios.post(
					`${API_BASE_URL}/mobile/remove_mobile_token`,
					{
						expoPushToken: deviceInfo.expoPushToken,
					}
				);
				// console.log("remove_mobile_token", response.data);

				if (response.data.message === "ok") {
					localStorage.removeItem("mobile_login");
					setShopData([]);
					setCheckDisplay(2);
					socket.emit("leave_room", shopData.shopId);
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	};

	return (
		<div>
			{checkDisplay === 0 ? (
				<Center minH={"100vh"} bgImage={ImageBg}>
					{checkErrorConnection ? (
						"ไม่สามารถติดต่อระบบได้"
					) : (
						<>
							<Flex direction={"column"}>
								<Image src={ImageLogo} w={{ base: "150px", md: "250px" }} />
							</Flex>
						</>
					)}
				</Center>
			) : checkDisplay === 1 ? (
				<>
					<Flex
						as="header"
						width="100%"
						height="60px"
						alignItems="center"
						padding="0 20px"
						bg="cyan.800"
						boxShadow="sm"
						px={{ base: 4, sm: 6, md: 10, lg: 14, xl: 16 }}
						position={"fixed"}
						top={0}
						zIndex={2}
					>
						<Flex gap={3}>
							<Box minH={"40px"} minW={"40px"}>
								{shopData !== null && (
									<Image
										src={
											shopData.shopLogo !== ""
												? `${API_BASE_URL}/image/logo/${shopData.shopLogo}`
												: `${API_BASE_URL}/image/logo/default.png`
										}
										alt="Logo"
										height="40px"
										filter="drop-shadow(0 0 5px white)"
									/>
								)}
							</Box>
							<Box color={"white"} lineHeight="1.2" pt={1}>
								<Text fontWeight={"bold"} fontSize={"lg"}>
									{shopData.shopName}
								</Text>
								<Text fontWeight={"300"} fontSize={"10px"}>
									sevscan.com
								</Text>
							</Box>
						</Flex>
						<Spacer />
						<IconButton
							aria-label="Exit"
							variant="ghost"
							onClick={handleLogout}
							_hover={"none"}
							icon={<IoLogOut fontSize={20} color="white" />}
						/>
					</Flex>

					<ScaleFade initialScale={0.6} in={true}>
						<Box
							px={{ base: 4, md: 10, lg: 14, xl: 16 }}
							py={{ base: 5, md: 6, lg: 8, xl: 10 }}
							mt={"60px"}
							minH="calc(100vh - 60px)"
						>
							<EmployeeDisplay shopData={shopData} />
						</Box>
					</ScaleFade>
				</>
			) : (
				<Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
					<Flex flex={2}>
						<Image
							alt={"Login Image"}
							objectFit={"cover"}
							src={imageLoginPublic}
							minH={"250px"}
						/>
					</Flex>
					<Flex p={8} flex={1} align={"center"} justify={"center"}>
						<Box w={"full"}>
							<Heading
								fontSize={"2xl"}
								color={"cyan.700"}
								mb={3}
								textAlign={"center"}
							>
								ลงชื่อเช้าใช้งาน
							</Heading>

							<Stack spacing={4} w={"full"}>
								<FormControl id="restaurant">
									<FormLabel>รหัสร้าน</FormLabel>
									<Input
										type="text"
										value={restaurantKey}
										onChange={(e) => setRestaurantKey(e.target.value)}
									/>
								</FormControl>
								<FormControl id="password">
									<FormLabel>รหัสผ่านพนักงาน</FormLabel>
									<InputGroup>
										<Input
											type={showPassword ? "text" : "password"}
											value={employeeKey}
											onChange={(e) => setEmployeeKey(e.target.value)}
										/>
										<InputRightElement h={"full"}>
											<Button
												variant={"ghost"}
												onClick={() =>
													setShowPassword((showPassword) => !showPassword)
												}
											>
												{showPassword ? <ViewIcon /> : <ViewOffIcon />}
											</Button>
										</InputRightElement>
									</InputGroup>
								</FormControl>
								<Stack spacing={6} pt={2}>
									<Button
										bg={"cyan.700"}
										color={"white"}
										_hover={{
											bg: "cyan.800",
										}}
										onClick={handleSubmit}
										isLoading={isLoadingSave}
										spinner={<BeatLoader size={8} color="white" />}
									>
										ลงชื่อ
									</Button>
								</Stack>
							</Stack>
						</Box>
					</Flex>
				</Stack>
			)}
		</div>
	);
}

export default MobileApp;
