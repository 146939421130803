import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import FacebookPixel from "./tracking/FacebookPixel";
import theme from "./theme";
import i18n from "./config/i18n";
import { I18nextProvider } from "react-i18next";

import PublicRoute from "./router/PublicRoute";
import PrivateRoute from "./router/PrivateRoute";
import TeamRoute from "./router/TeamRoute";

import AuthProvider from "./hooks/AuthProvider";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import QRExample from "./pages/QRExample";
import PageManual from "./pages/PageManual";
import Restaurant from "./pages/Restaurant";
import Accommodation from "./pages/Accommodation";
import Faq from "./pages/Faq";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Acc from "./pages/Acc";
import PrivacyPolicy from "./components/component/PrivacyPolicy";
import CardPrice from "./pages/component/CardPrice";

import Customer from "./mobile/Customer";
import CustomerNoCode from "./mobile/CustomerNoCode";
import DemoMenu from "./mobile/DemoMenu";
import SendReScan from "./mobile/SendReScan";
import MobileApp from "./mobile/MobileApp";
// import MobileApp2 from "./mobile/MobileApp2";

import Service from "./components/content/Service";
import Category from "./components/content/Category";
import Product from "./components/content/Product";
import Unit from "./components/content/Unit";
import Employee from "./components/content/Employee";
import Shop from "./components/content/Shop";
import Dashboard from "./components/content/Dashboard";
import Manual from "./components/content/Manual";
import Update from "./components/content/Update";
import Renew from "./components/content/Renew";
import { CheckoutForm, Return } from "./components/component/Stripe";

import Monitor from "./admin/Monitor";

import Intro from "./team/content/Home";
import Revenue from "./team/content/Revenue";
import Register from "./team/component/Register";
import LoginTeam from "./team/component/Login";
import Profile from "./team/content/Profile";

function App() {
	return (
		<ChakraProvider theme={theme}>
			<I18nextProvider i18n={i18n}>
				<Router basename="/">
					<FacebookPixel />
					<AuthProvider>
						<Routes>
							{/* Fallback route */}
							<Route path="*" element={<NotFound />} />

							{/* Public routes */}
							<Route path="/login" element={<Login />} />
							<Route path="/forgot_pw" element={<ForgotPassword />} />
							<Route path="/reset_pw/:token" element={<ResetPassword />} />
							<Route path="/menu/:shopname" element={<Customer />} />
							<Route path="/demo/:shopname" element={<DemoMenu />} />
							<Route path="/menu" element={<CustomerNoCode />} />
							<Route path="/rescan" element={<SendReScan />} />
							<Route path="/acc" element={<Acc />} />
							<Route path="/mobile_app" element={<MobileApp />} />
							<Route path="/mobile_app2" element={<MobileApp />} />

							{/* Grouped PublicRoute paths */}
							<Route element={<PublicRoute />}>
								<Route path="/" element={<Home />} />
								<Route path="/about" element={<About />} />
								<Route path="/contact" element={<Contact />} />
								<Route path="/restaurant" element={<Restaurant />} />
								<Route path="/accommodation" element={<Accommodation />} />
								<Route path="/faq" element={<Faq />} />
								<Route path="/pmanual" element={<PageManual />} />
								<Route path="/qrexample" element={<QRExample />} />
								<Route path="/privacy" element={<PrivacyPolicy />} />
								<Route path="/price" element={<CardPrice showFull={true} />} />
							</Route>

							{/* Grouped PrivateRoute paths */}
							<Route element={<PrivateRoute />}>
								<Route path="/service" element={<Service />} />
								<Route path="/category" element={<Category />} />
								<Route path="/product" element={<Product />} />
								<Route path="/shop" element={<Shop />} />
								<Route path="/unit" element={<Unit />} />
								<Route path="/employee" element={<Employee />} />
								<Route path="/dashboard" element={<Dashboard />} />
								<Route path="/manual" element={<Manual />} />
								<Route path="/update" element={<Update />} />
								<Route path="/renew" element={<Renew />} />
								<Route element={<Renew />}>
									<Route path="/checkout" element={<CheckoutForm />} />
								</Route>
								<Route path="/return" element={<Return />} />
							</Route>

							<Route path="/admin" element={<Monitor />} />

							{/* Team-related routes */}
							<Route element={<TeamRoute />}>
								<Route path="/team" element={<Intro />} />
								<Route path="/team/revenue" element={<Revenue />} />
								<Route path="/team/register" element={<Register />} />
								<Route path="/team/login" element={<LoginTeam />} />
								<Route path="/team/profile" element={<Profile />} />
							</Route>

							{/* ยืนยัน startupranking.com */}
							<Route
								path="/startupranking1337313818061910.html"
								element={
									<Navigate
										to={"/startupranking1337313818061910.html"}
										replace
									/>
								}
							/>
						</Routes>
					</AuthProvider>
				</Router>
			</I18nextProvider>
		</ChakraProvider>
	);
}

export default App;
