import React, { useState } from "react";
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Button,
	Text,
	Badge,
	Flex,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

const UserPayment = ({ data, rowsPerPage = 10 }) => {
	const [page, setPage] = useState(0);
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: "asc",
	});

	// Sorting function
	const sortedData = React.useMemo(() => {
		let sortableData = [...data];
		if (sortConfig.key !== null) {
			sortableData.sort((a, b) => {
				const aValue = a[sortConfig.key] || "";
				const bValue = b[sortConfig.key] || "";

				if (aValue < bValue) {
					return sortConfig.direction === "asc" ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortConfig.direction === "asc" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableData;
	}, [data, sortConfig]);

	// Pagination calculations
	const start = page * rowsPerPage;
	const end = start + rowsPerPage;
	const paginatedData = sortedData.slice(start, end);
	const totalPages = Math.ceil(data.length / rowsPerPage);

	const handleSort = (key) => {
		setSortConfig((prevConfig) => ({
			key,
			direction:
				prevConfig.key === key && prevConfig.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	return (
		<Box>
			<TableContainer>
				<Table variant="simple">
					<Thead bgColor={"cyan.600"}>
						<Tr>
							<Th>
								<Button
									textColor={"white"}
									px={0}
									variant={"ghost"}
									_hover={"none"}
									onClick={() => handleSort("first_name")}
									rightIcon={
										sortConfig.key === "first_name" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									ผู้สมัคร
								</Button>
							</Th>
							<Th textAlign={"center"}>
								<Button
									textColor={"white"}
									px={0}
									variant={"ghost"}
									_hover={"none"}
									onClick={() => handleSort("plan")}
									rightIcon={
										sortConfig.key === "plan" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									ต่ออายุ
								</Button>
							</Th>
							<Th textAlign={"center"}>
								<Button
									textColor={"white"}
									px={0}
									variant={"ghost"}
									_hover={"none"}
									onClick={() => handleSort("datetime")}
									rightIcon={
										sortConfig.key === "datetime" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									วันที่
								</Button>
							</Th>
							<Th textAlign={"center"}>
								<Button
									textColor={"white"}
									px={0}
									variant={"ghost"}
									_hover={"none"}
									onClick={() => handleSort("revenue")}
									rightIcon={
										sortConfig.key === "revenue" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									รายได้
								</Button>
							</Th>
							<Th textAlign={"right"}>
								<Button
									textColor={"white"}
									px={0}
									variant={"ghost"}
									_hover={"none"}
									onClick={() => handleSort("withdraw_status")}
									rightIcon={
										sortConfig.key === "withdraw_status" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									การถอน
								</Button>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{paginatedData.length > 0 ? (
							paginatedData.map((row, index) => (
								<Tr key={index} fontSize="sm">
									<Td>{`${row.first_name || ""} ${row.last_name || ""}`}</Td>
									<Td textAlign={"center"}>{row.plan} ปี</Td>
									<Td textAlign={"center"}>
										{new Date(row.datetime).toLocaleString("th-TH", {
											year: "numeric",
											month: "long",
											day: "numeric",
										})}
									</Td>
									<Td textAlign={"center"}>{row.revenue} บาท</Td>
									<Td textAlign={"right"}>
										{row.withdraw_status === "waiting" ? (
											<Badge colorScheme="orange">รอถอน</Badge>
										) : (
											<Badge colorScheme="green">ถอนแล้ว</Badge>
										)}
									</Td>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={6} textAlign="center" py={5}>
									<Text color="gray.500">ไม่มีข้อมูล</Text>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{data.length > 10 && (
				<Box display="flex" alignItems="center" mt={4}>
					<Flex gap={2} justifyContent="center" width="100%">
						<Button
							size="sm"
							onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
							disabled={page === 0}
						>
							ก่อนหน้า
						</Button>
						<Button size="sm" _hover={{}}>
							หน้า {page + 1} / {totalPages}
						</Button>
						<Button
							size="sm"
							onClick={() =>
								setPage((prev) => Math.min(prev + 1, totalPages - 1))
							}
							disabled={page === totalPages - 1}
						>
							ถัดไป
						</Button>
					</Flex>
				</Box>
			)}
		</Box>
	);
};

export default UserPayment;
