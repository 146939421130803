import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
	Box,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Spacer,
	Text,
	Flex,
	Card,
	Heading,
	Table,
	Tbody,
	Tr,
	Td,
	Modal,
	Button,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	FormControl,
	FormLabel,
	Select,
	Input,
	useDisclosure,
	Link,
	useToast,
} from "@chakra-ui/react";
import TeamContext from "../../hooks/TeamContext";
import UserUnder from "../component/UserUnder";
import UserPayment from "../component/UserPayment";
import Withdraw from "../component/Withdraw";
import Promote from "../component/Promote";
import { API_BASE_URL } from "../../config/apiConfig";

const calculateDaysRemaining = (expiryDate) => {
	const now = new Date();
	const expiration = new Date(expiryDate);
	const differenceInTime = expiration - now;
	const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
	return differenceInDays > 0 ? differenceInDays : 0; // Return 0 if already expired
};

function Profile() {
	const { teamUser } = useContext(TeamContext);

	const [userData, setUserData] = useState([]);
	const [paymentData, setPaymentData] = useState([]);
	const [withdrawHistoryData, setWithdrawHistoryData] = useState([]);
	const toast = useToast();

	// Helper function to format numbers
	const formatNumber = (value) =>
		Number(value).toLocaleString(undefined, {
			minimumFractionDigits: value % 1 === 0 ? 0 : 2,
			maximumFractionDigits: 2,
		});

	const waitingWithdraw = paymentData.reduce(
		(total, item) =>
			item.withdraw_status === "waiting"
				? total + (item.plan === 1 ? 146 : item.plan === 2 ? 262.8 : 350.4)
				: total,
		0
	);

	const successWithdrawn = paymentData.reduce(
		(total, item) =>
			item.withdraw_status === "success"
				? total + (item.plan === 1 ? 146 : item.plan === 2 ? 262.8 : 350.4)
				: total,
		0
	);

	const navigate = useNavigate();
	const team_data = JSON.parse(localStorage.getItem("team_data"));
	useEffect(() => {
		const fetchTeamUserData = async () => {
			if (!team_data.token) {
				toast({
					title: "ลงชื่อเข้าใช้ใหม่อีกครั้ง",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 2000,
				});
				return;
			}

			try {
				const { data } = await axios.post(
					`${API_BASE_URL}/team/team_user_data`,
					{ payment_list: true },
					{ headers: { Authorization: team_data.token } }
				);

				// console.log(data.userData, data.paymentData);

				const dataWithDaysRemaining = data.userData.map((item) => ({
					...item,
					daysRemaining: calculateDaysRemaining(item.last_day),
				}));

				const dataPaymentData = data.paymentData.map((item) => ({
					...item,
					revenue: item.plan === 1 ? 146 : item.plan === 2 ? 262.8 : 350.4,
				}));

				setUserData(dataWithDaysRemaining || []); // Set payment data or empty array if no result
				setPaymentData(dataPaymentData || []);
				setWithdrawHistoryData(data.withdrawHistoryData || []);
			} catch (error) {
				console.error("Error fetching payment data:", error);
				toast({
					title: "เกิดข้อผิดพลาดในการลงชื่อเข้าใช้",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			}
		};

		if (team_data === null) {
			navigate("/team/login");
		}
		if (userData.length === 0) {
			fetchTeamUserData();
		}
	}, [toast, navigate, team_data, userData]);

	return (
		<>
			<Box>
				{teamUser && (
					<Card px={5} pt={5} pb={4} mb={6} boxShadow={"none"}>
						<Heading size="sm" textTransform="uppercase" mb={2}>
							ยินดีต้อนรับ {teamUser.first_name} {teamUser.last_name}
						</Heading>
						<Flex direction={{ base: "column", md: "row" }} gap={2}>
							<Box fontSize="sm">
								<Box gap={4}>
									<Text as="span" fontWeight="600" pr={2}>
										อีเมล:
									</Text>
									{teamUser.email}
								</Box>
								<Box gap={4}>
									<Text as="span" fontWeight="600" pr={2}>
										ลิงค์แนะนำ:
									</Text>
									<Link
										href={`/?ref=${teamUser.referral_code}`}
										color="orange.500"
										fontWeight={"bold"}
										isExternal
									>
										https://sevscan.com/?ref={teamUser.referral_code}
									</Link>
								</Box>
								<Box gap={4}>
									<Text as="span" fontWeight="600" pr={2}>
										ธนาคาร:
									</Text>
									<BankFormModal teamUser={teamUser} />
								</Box>
							</Box>

							<Spacer />
							<Box textAlign={"right"}>
								<Table py={0} px={2} variant="unstyled">
									<Tbody fontSize="sm">
										{[
											{
												label: "ผู้สมัคร",
												value: userData.length,
												unit: "คน",
											},
											{
												label: "รอถอน",
												value: waitingWithdraw,
												unit: "บาท",
											},
											{
												label: "ถอนแล้ว",
												value: successWithdrawn,
												unit: "บาท",
											},
										].map((row, index) => (
											<Tr key={index}>
												<Td py={0} px={{ base: 0, md: 2 }} textAlign="left">
													{row.label}
												</Td>
												<Td py={0} px={2} textAlign="right" fontWeight="bold">
													{formatNumber(row.value)}
												</Td>

												<Td
													py={0}
													px={2}
													textAlign={{ base: "right", md: "center" }}
												>
													{row.unit}
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</Box>
						</Flex>
					</Card>
				)}

				<Card p={5} pb={2} boxShadow={"none"}>
					<Tabs variant="unstyled">
						<Box overflowX="auto" whiteSpace="nowrap">
							<TabList gap={2} display="inline-flex">
								<Tab
									bgColor="gray.100"
									_selected={{ color: "white", bg: "orange.400" }}
									flexShrink={0}
								>
									ผู้สมัครทั้งหมด
								</Tab>
								<Tab
									bgColor="gray.100"
									_selected={{ color: "white", bg: "orange.400" }}
									flexShrink={0}
								>
									ประวัติการต่ออายุ
								</Tab>
								<Tab
									bgColor="gray.100"
									_selected={{ color: "white", bg: "orange.400" }}
									flexShrink={0}
								>
									ประวัติการถอนเงิน
								</Tab>
								<Tab
									bgColor="gray.100"
									_selected={{ color: "white", bg: "orange.400" }}
									flexShrink={0}
								>
									ภาพประกอบ
								</Tab>
							</TabList>
						</Box>

						<TabPanels pt={2}>
							<TabPanel px={0} minH={"calc(100vh - 380px)"}>
								<UserUnder data={userData} />
							</TabPanel>
							<TabPanel px={0} minH={"calc(100vh - 380px)"}>
								<UserPayment data={paymentData} />
							</TabPanel>
							<TabPanel px={0} minH={"calc(100vh - 380px)"}>
								<Withdraw data={withdrawHistoryData} />
							</TabPanel>
							<TabPanel px={0} minH={"calc(100vh - 380px)"}>
								<Promote />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Card>
			</Box>
		</>
	);
}
function BankFormModal({ teamUser }) {
	// console.log("teamUser", teamUser);
	const toast = useToast();
	const { setTeamUser } = useContext(TeamContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [bank, setBank] = useState("");
	const [accountName, setAccountName] = useState("");
	const [accountNumber, setAccountNumber] = useState("");

	const handleSubmit = async () => {
		try {
			// Trim values and validate they are not empty
			const trimmedBank = bank.trim();
			const trimmedAccountName = accountName.trim();
			const trimmedAccountNumber = accountNumber.trim();

			if (!trimmedBank || !trimmedAccountName || !trimmedAccountNumber) {
				alert("กรุณากรอกข้อมูลธนาคาร ชื่อบัญชี และเลขที่บัญชี");
				return;
			}

			if (!teamUser.token) {
				toast({
					title: "ลงชื่อเข้าใช้ใหม่อีกครั้ง",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 2000,
				});
				return;
			}

			// console.log(
			// 	"Token:",
			// 	team_data.token,
			// 	"Bank:",
			// 	trimmedBank,
			// 	"Account Name:",
			// 	trimmedAccountName,
			// 	"Account Number:",
			// 	trimmedAccountNumber
			// );

			// Send data to the API with token in headers
			const response = await axios.post(
				`${API_BASE_URL}/team/update_bank`,
				{
					bank: trimmedBank,
					accountName: trimmedAccountName,
					accountNumber: trimmedAccountNumber,
				},
				{
					headers: {
						Authorization: teamUser.token,
					},
				}
			);

			// console.log("Response:", response.data);

			if (response.data.message === "success") {
				setTeamUser((prevUser) => {
					const updatedUser = {
						...prevUser,
						bank: trimmedBank,
						account_name: trimmedAccountName,
						account_number: trimmedAccountNumber,
					};
					localStorage.setItem("team_data", JSON.stringify(updatedUser));

					return updatedUser;
				});
				onClose();
			} else {
				alert(response.data.message);
			}
		} catch (error) {
			console.error("Error submitting bank details:", error);
		}
	};

	return (
		<>
			{teamUser.bank && teamUser.account_name && teamUser.account_number ? (
				<>
					{teamUser.bank} {teamUser.account_name} {teamUser.account_number}{" "}
					<Link color={"blue.400"} onClick={() => onOpen()}>
						<u>แก้ไข</u>
					</Link>
				</>
			) : (
				<>
					<Link color={"blue.300"} onClick={() => onOpen()}>
						<u>เพิ่ม</u>
					</Link>
				</>
			)}

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>บัญชีสำหรับถอนเงิน</ModalHeader>
					<ModalBody>
						<FormControl id="bank" mb={4}>
							<FormLabel>ธนาคาร</FormLabel>
							<Select
								placeholder="เลือกธนาคาร"
								value={bank}
								onChange={(e) => setBank(e.target.value)}
							>
								<option value="กรุงเทพ">กรุงเทพ (BBL)</option>
								<option value="ไทยพาณิชย์">ไทยพาณิชย์ (SCB)</option>
								<option value="กรุงไทย">กรุงไทย (KTB)</option>
								<option value="กสิกรไทย">กสิกรไทย (KBank)</option>
								<option value="ทหารไทยธนชาต">ทหารไทยธนชาต (TTB)</option>
								<option value="กรุงศรีอยุธยา">กรุงศรีอยุธยา (BAY)</option>
								<option value="ยูโอบี">ยูโอบี (UOB)</option>
								<option value="ออมสิน">ออมสิน (GSB)</option>
								<option value="อิสลามแห่งประเทศไทย">
									อิสลามแห่งประเทศไทย (IBank)
								</option>
								<option value="ซีไอเอ็มบี ไทย">ซีไอเอ็มบี ไทย (CIMB)</option>
								<option value="แลนด์ แอนด์ เฮ้าส์">
									แลนด์ แอนด์ เฮ้าส์ (LHB)
								</option>
								<option value="ทิสโก้">ทิสโก้ (TISCO)</option>
								<option value="เกียรตินาคินภัทร">เกียรตินาคินภัทร (KKP)</option>
								<option value="เพื่อการเกษตรและสหกรณ์การเกษตร">
									เพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)
								</option>
							</Select>
						</FormControl>

						<FormControl id="accountName" mb={4}>
							<FormLabel>ชื่อบัญชี</FormLabel>
							<Input
								type="text"
								placeholder=""
								value={accountName}
								onChange={(e) => setAccountName(e.target.value)}
							/>
						</FormControl>

						<FormControl id="accountNumber">
							<FormLabel>เลขบัญชี</FormLabel>
							<Input
								type="text"
								placeholder=""
								value={accountNumber}
								onChange={(e) => setAccountNumber(e.target.value)}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={handleSubmit}>
							บันทึก
						</Button>
						<Button variant="ghost" onClick={onClose}>
							ยกเลิก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Profile;
