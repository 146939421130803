// import React from "react";
// import {
// 	Box,
// 	chakra,
// 	Container,
// 	Stack,
// 	Text,
// 	useColorModeValue,
// 	VisuallyHidden,
// 	Image,
// } from "@chakra-ui/react";
// import { FaSquareFacebook, FaLine } from "react-icons/fa6";
// import logo from "../images/logo250.png";

// const SocialButton = ({ children, label, href }) => {
// 	return (
// 		<chakra.button
// 			bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
// 			rounded={"full"}
// 			w={8}
// 			h={8}
// 			cursor={"pointer"}
// 			as={"a"}
// 			href={href}
// 			display={"inline-flex"}
// 			alignItems={"center"}
// 			justifyContent={"center"}
// 			transition={"background 0.3s ease"}
// 			_hover={{
// 				bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
// 			}}
// 			target="_blank"
// 		>
// 			<VisuallyHidden>{label}</VisuallyHidden>
// 			{children}
// 		</chakra.button>
// 	);
// };

// function Footer() {
// 	return (
// 		<Box bg={useColorModeValue("cyan.800", "gray.900")} color={"gray.200"}>
// 			<Container
// 				as={Stack}
// 				maxW={"container.xl"}
// 				py={4}
// 				direction={{ base: "column", md: "row" }}
// 				spacing={2}
// 				justify={{ base: "center", md: "space-between" }}
// 				align={{ base: "center", md: "center" }}
// 			>
// 				<Image src={logo} h={8} />
// 				<Text textAlign={"center"}>
// 					© 2024 sevscan templates. All rights reserved
// 				</Text>
// 				<Stack direction={"row"} spacing={2}>
// 					<SocialButton label={"Line"} href={"https://lin.ee/R9RbXAm"}>
// 						<FaLine fontSize="27px" />
// 					</SocialButton>
// 					<SocialButton
// 						label={"Facebook"}
// 						href={"https://www.facebook.com/100091347363576"}
// 					>
// 						<FaSquareFacebook fontSize="30px" />
// 					</SocialButton>
// 				</Stack>
// 			</Container>
// 		</Box>
// 	);
// }

// export default Footer;

import React from "react";
import {
	Flex,
	HStack,
	Box,
	Image,
	Divider,
	Icon,
	Link,
	Stack,
	VStack,
	Text,
	Container,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn, FaYoutube, FaLine } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { GrInstagram } from "react-icons/gr";
import { SiPinterest } from "react-icons/si";
import Logo2Line from "../images/logo2line.png";

function Footer() {
	const navigate = useNavigate();

	return (
		<Box
			bg="cyan.700"
			_dark={{
				bg: "gray.600",
			}}
			color={"white"}
		>
			<Container maxW="container.xl">
				<Stack
					direction={{
						base: "column",
						lg: "row",
					}}
					w="full"
					justify="space-between"
					py={5}
					px={10}
				>
					<Flex justify="center">
						<Image
							src={Logo2Line}
							alt="Company Logo"
							height={{
								base: "50px",
								lg: "50px",
							}}
							my={{
								base: 2,
								lg: 0,
							}}
						/>
					</Flex>
					<HStack
						alignItems="start"
						flex={1}
						justify="space-around"
						fontSize={{
							md: "16px",
						}}
						color="gray.100"
						_dark={{
							color: "white",
						}}
						textAlign={{
							base: "center",
							md: "right",
						}}
					>
						<Flex justify="start" direction="column">
							<Link onClick={() => navigate("/pmanual")}>ฟังก์ชั่นทั้งหมด</Link>
							<Link onClick={() => navigate("/qrexample")}>
								ตัวอย่าง QR Menu
							</Link>
						</Flex>
						<Flex justify="start" direction="column">
							<Link onClick={() => navigate("/contact")}>ติดต่อสอบถาม</Link>
							<Link onClick={() => navigate("/privacy")}>
								นโยบายความเป็นส่วนตัว
							</Link>
						</Flex>
					</HStack>
					<HStack
						alignItems="start"
						flex={1}
						justify="space-around"
						fontSize={{
							md: "16px",
						}}
						color="gray.100"
						_dark={{
							color: "white",
						}}
						textAlign={{
							base: "center",
							md: "right",
						}}
					>
						<Flex justify="start" direction="column">
							<Link onClick={() => navigate("/price")}>ค่าบริการ</Link>
							<Link href="/team" target="_blank">
								สมัครเป็นพันธมิตร
							</Link>
						</Flex>
						<Flex justify="start" direction="column">
							<Link
								href="https://play.google.com/store/apps/details?id=com.sevscan.app"
								isExternal
							>
								<Image
									src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
									alt="Get it on Google Play"
									w="150px"
									h="auto"
								/>
							</Link>
						</Flex>
					</HStack>
				</Stack>
				<Divider
					w="98%"
					mx="auto"
					color="gray.600"
					_dark={{
						color: "#F9FAFB",
					}}
					h="3.5px"
				/>
				<VStack py={3}>
					<HStack justify="center">
						<Link display={"none"}>
							<Icon
								color="gray.800"
								_dark={{
									color: "white",
								}}
								h="20px"
								w="20px"
								as={FiTwitter}
							/>
						</Link>
						<Link display={"none"}>
							<Icon
								_dark={{
									color: "white",
								}}
								h="20px"
								w="20px"
								as={GrInstagram}
							/>
						</Link>
						<Link display={"none"}>
							<Icon
								_dark={{
									color: "white",
								}}
								h="20px"
								w="20px"
								as={FaLinkedinIn}
							/>
						</Link>
						<Link display={"none"}>
							<Icon
								_dark={{
									color: "white",
								}}
								h="20px"
								w="20px"
								as={SiPinterest}
							/>
						</Link>
						<Link
							href={"https://www.facebook.com/100091347363576"}
							target="_blank"
						>
							<Icon
								_dark={{
									color: "white",
								}}
								h="20px"
								w="20px"
								as={FaFacebookF}
							/>
						</Link>
						<Link href={"https://www.youtube.com/@sevscan"} target="_blank">
							<Icon
								_dark={{
									color: "white",
								}}
								h="25px"
								w="25px"
								as={FaYoutube}
							/>
						</Link>
						<Link href={"https://lin.ee/R9RbXAm"} target="_blank" pl={1}>
							<Icon
								_dark={{
									color: "white",
								}}
								h="25px"
								w="25px"
								as={FaLine}
							/>
						</Link>
					</HStack>

					<Text
						textAlign="center"
						fontSize="smaller"
						_dark={{
							color: "white",
						}}
					>
						&copy; 2024 sevscan templates. All rights reserved
					</Text>
				</VStack>
			</Container>
		</Box>
	);
}

export default Footer;
