import React, { useState, useMemo, useEffect, useContext } from "react";
import axios from "axios";
import {
	Flex,
	Box,
	Card,
	Container,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Input,
	ModalFooter,
	Button,
	useDisclosure,
	SimpleGrid,
	FormLabel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Center,
	useColorModeValue,
	Text,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { EditIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import ImageDownloadAPK from "../../images/image_download_apk.jpg";

function Employee() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [employee, setEmployee] = useState({});
	const [oldPassword, setOldPassword] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/employee`,
					{ shopValueId },
					{ headers }
				);
				// console.log("em", response.data.results);
				setEmployee(response.data.results);
				setOldPassword(response.data.results[0].password);
			} catch (error) {
				console.error("Error get employee:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [password, setPassword] = useState();
	const handleEdit = () => {
		// console.log(data);
		setPassword(oldPassword);
		onOpen();
	};
	const handleSave = async () => {
		const trimmedPassword = password.trim();
		// console.log("trimmedPassword", trimmedPassword);

		if (!trimmedPassword) {
			toast({
				title: `กรอกรหัสผ่าน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/employee_update_password`,
				{
					editId: employee[0].id,
					trimmedPassword,
				},
				{ headers }
			);
			if (response.data.message === "success") {
				setOldPassword(trimmedPassword);
				onClose();
				toast({
					title: `เรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
			} else {
				console.error("Error category_add");
			}
		} catch (error) {
			console.error("Error saving category:", error);
		}
	};

	const [deviceList, showDeviceList] = useState(0);

	return (
		<>
			<Flex
				alignItems={{ base: "auto", md: "center" }}
				mt={{ base: "auto", md: 3 }}
			>
				<Container maxW={"3xl"} px={"none"}>
					<Card boxShadow={"none"} borderRadius={"1em"}>
						<Center>
							<Box
								pos={"relative"}
								h={{ base: "200px", sm: "270px", md: "250px", lg: "320px" }}
								w={"full"}
								bg={useColorModeValue("white", "gray.800")}
								borderTopRadius={"1em"}
								overflow={"hidden"}
								bgImage={ImageDownloadAPK}
								bgSize="cover"
								bgPosition="center"
								bgRepeat="no-repeat"
							>
								<Button
									pos={"absolute"}
									left={4}
									top={4}
									colorScheme="blackAlpha"
									size={{ base: "sm", md: "md" }}
									_hover={{ cursor: "auto" }}
								>
									ติดตั้งแอพพลิเคชั่น
								</Button>
							</Box>
						</Center>

						<Box
							pt={{ base: 6, md: 10 }}
							px={{ base: 6, md: 10 }}
							pb={{ base: 3, md: 5 }}
							boxShadow={"md"}
							borderBottomRadius={"2xl"}
						>
							<SimpleGrid
								columns={{ base: 2, md: 2 }}
								spacing={{ base: 4, md: 5 }}
							>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									APK File
								</Box>
								<Box color={"gray.500"} textAlign={"right"}>
									<Button
										mt={-2}
										w={32}
										rounded={"md"}
										bgColor={"orange.200"}
										_hover={{
											transform: "translateY(-2px)",
											boxShadow: "lg",
										}}
										onClick={() =>
											(window.location.href =
												"https://file.sevscan.com/app_sevscan.apk")
										}
										size={{ base: "sm", md: "sm" }}
										leftIcon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="1.4em"
												height="1.4em"
												viewBox="0 0 48 48"
											>
												<g fill="none">
													<path
														stroke="#4285f4"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={4}
														d="M27 40H7C5.34315 40 4 38.6569 4 37V11C4 9.34315 5.34315 8 7 8H41C42.6569 8 44 9.34315 44 11V24"
													></path>
													<path
														stroke="#4285f4"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={4}
														d="M34 36L39 41L44 36"
													></path>
													<path
														stroke="#4285f4"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={4}
														d="M39 29L39 41"
													></path>
													<path
														fill="#fcbc05"
														stroke="#4285f4"
														strokeWidth={4}
														d="M4 11C4 9.34315 5.34315 8 7 8H41C42.6569 8 44 9.34315 44 11V20H4V11Z"
													></path>
													<circle
														r={2}
														fill="#eb4335"
														transform="matrix(0 -1 -1 0 10 14)"
													></circle>
													<circle
														r={2}
														fill="#eb4335"
														transform="matrix(0 -1 -1 0 16 14)"
													></circle>
												</g>
											</svg>
										}
									>
										Download
									</Button>
								</Box>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									Android
								</Box>
								<Box color={"gray.500"} textAlign={"right"}>
									<Button
										mt={-2}
										w={32}
										rounded={"md"}
										bgColor={"orange.200"}
										_hover={{
											transform: "translateY(-2px)",
											boxShadow: "lg",
										}}
										onClick={() =>
											window.open(
												"https://play.google.com/store/apps/details?id=com.sevscan.app",
												"_blank"
											)
										}
										size={{ base: "sm", md: "sm" }}
										leftIcon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="1.2em"
												height="1.2em"
												viewBox="0 0 256 283"
											>
												<path
													fill="#ea4335"
													d="M119.553 134.916L1.06 259.061a32.14 32.14 0 0 0 47.062 19.071l133.327-75.934z"
												/>
												<path
													fill="#fbbc04"
													d="M239.37 113.814L181.715 80.79l-64.898 56.95l65.162 64.28l57.216-32.67a31.345 31.345 0 0 0 0-55.537z"
												/>
												<path
													fill="#4285f4"
													d="M1.06 23.487A30.6 30.6 0 0 0 0 31.61v219.327a32.3 32.3 0 0 0 1.06 8.124l122.555-120.966z"
												/>
												<path
													fill="#34a853"
													d="m120.436 141.274l61.278-60.483L48.564 4.503A32.85 32.85 0 0 0 32.051 0C17.644-.028 4.978 9.534 1.06 23.399z"
												/>
											</svg>
										}
									>
										Play Store
									</Button>
								</Box>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									Apple
								</Box>
								<Box color={"gray.500"} textAlign={"right"}>
									<Button
										mt={-2}
										w={32}
										rounded={"md"}
										// bgColor={"orange.200"}
										size={{ base: "sm", md: "sm" }}
										_hover={{ cursor: "auto" }}
									>
										Coming soon
									</Button>
								</Box>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									Huawei
								</Box>
								<Box color={"gray.500"} textAlign={"right"}>
									<Button
										mt={-2}
										w={32}
										rounded={"md"}
										// bgColor={"orange.200"}
										size={{ base: "sm", md: "sm" }}
										_hover={{ cursor: "auto" }}
									>
										Coming soon
										{/* APPGallery */}
									</Button>
								</Box>
							</SimpleGrid>
						</Box>
						<Box
							pt={{ base: 6, md: 10 }}
							px={{ base: 6, md: 10 }}
							pb={{ base: 2, md: 3 }}
							boxShadow={"md"}
							borderBottomRadius={"2xl"}
						>
							<SimpleGrid
								columns={{ base: 2, md: 2 }}
								spacing={{ base: 4, md: 5 }}
							>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									รหัสร้าน
								</Box>
								<Box textAlign="right">
									<Box
										mt={-2}
										py={1.5}
										w={32}
										rounded="md"
										bgColor="gray.100"
										textAlign="center"
										ml="auto"
										fontSize={"sm"}
										fontWeight={"500"}
									>
										{employee.length > 0 ? (
											employee[0].code
										) : (
											<BeatLoader size={8} color="gray" />
										)}
									</Box>
								</Box>

								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									รหัสผ่านพนักงาน
								</Box>
								<Box textAlign={"right"}>
									<Box
										mt={-2}
										py={1.5}
										w={32}
										rounded="md"
										bgColor="gray.100"
										textAlign="center"
										ml="auto"
										fontSize={"sm"}
										fontWeight={"500"}
									>
										{employee.length > 0 ? (
											oldPassword
										) : (
											<BeatLoader size={8} color="gray" />
										)}
									</Box>
									{employee.length > 0 && (
										<EditIcon
											onClick={() => handleEdit()}
											color="cyan.700"
											cursor="pointer"
										/>
									)}
								</Box>
								<Box fontSize={"sm"} color={"red"} pb={2}>
									ติดตั้งแอพและเปิดการแจ้งเตือน
								</Box>
								<Box textAlign={"right"} fontSize={"sm"} pb={2}>
									{employee[0]?.brand ? (
										<Text
											onClick={() => showDeviceList(!deviceList)}
											as={"u"}
											cursor={"pointer"}
											color={"blue.500"}
										>
											ติดตั้งแล้ว {employee.length} เครื่อง
										</Text>
									) : (
										"อย่างน้อย 1 เครื่องก่อนใช้งาน"
									)}
								</Box>
							</SimpleGrid>
							<TableContainer display={deviceList ? "auto" : "none"} pb={4}>
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th
												color={"cyan.700"}
												fontSize={"md"}
												fontWeight={"600"}
												pl={0}
											>
												Brand
											</Th>
											<Th
												color={"cyan.700"}
												fontSize={"md"}
												fontWeight={"600"}
												pr={0}
												isNumeric
											>
												Model
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{employee.length > 0 ? (
											employee[0]?.brand ? (
												employee.map((item, index) => (
													<Tr key={index}>
														<Td fontSize={"sm"} py={2} pl={0}>
															{item.brand}
														</Td>
														<Td fontSize={"sm"} py={2} pr={0} isNumeric>
															{item.model}
														</Td>
													</Tr>
												))
											) : (
												<Tr>
													<Td
														colSpan={2}
														textAlign={"center"}
														fontSize={"sm"}
														px={0}
													>
														- ยังไม่มี -
													</Td>
												</Tr>
											)
										) : (
											<Tr>
												<Td
													colSpan={2}
													textAlign={"center"}
													fontSize={"sm"}
													px={0}
												>
													<BeatLoader size={8} color="gray" />
												</Td>
											</Tr>
										)}
									</Tbody>
								</Table>
							</TableContainer>
						</Box>
					</Card>
				</Container>
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody pt={4}>
						<FormLabel>เปลี่ยนรหัสผ่านพนักงาน</FormLabel>
						<Input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" onClick={onClose} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="blue" onClick={() => handleSave()}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Employee;
